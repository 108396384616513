import s from "./TimeModelValueView.module.css";

interface TimeModelValueViewProps {
  title: string;
  description: string;
  value: any;
}
export const TimeModelValueView: React.FC<TimeModelValueViewProps> = ({
  value,
}: any) => {
  return (
    <div className={s.unitValue}>{value}</div>
  );
};