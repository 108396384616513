import { observer } from "mobx-react-lite";
import styles from "@src/views/mission/style/mission.module.scss";
// import {ArtifactItem} from "@src/components/Artifacts/ArtifactItem";

const Artifact = observer(({ item }: any) => {
  // const { title, subtitle, buttonContext } = item
  return (
    <>
      <div className={'p-4'}>
        <span>有緣遇見</span>
      </div>
      <div className={styles.second}>
        {/* <ArtifactItem type={'魂'} />
        <ArtifactItem type={'憶'} />
        <ArtifactItem type={'物'} />
        <ArtifactItem type={'識'} /> */}
        <img src={'https://storage.googleapis.com/artifacts-cms/Group_33689_51f10a42e0/Group_33689_51f10a42e0.png?updated_at=2022-09-07T10:58:47.561Z'}
                             alt={'temp'}/>
      </div>
    </>
  )
}
);

export default Artifact;
