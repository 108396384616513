import {Modal} from "react-bootstrap";
import styles from '../styles.module.scss';
import {observer} from "mobx-react-lite";
import Options from "@src/views/game/components/Step/components/Stepper";
import Form from 'react-bootstrap/Form';
import {useEffect, useState} from "react";

const MessageInputPopup = observer(({stepContent, stepController}: any) => {
    const [message, setMessage] = useState('')
    const [correct, setCorrect] = useState(false)
    console.log(stepContent);
    useEffect(() => {
        if (stepContent.validation && stepContent.answer) {
            if (stepContent.answer === message) setCorrect(true)
            else setCorrect(false)
        } else {
            setCorrect(true)
        }
    }, [message])
    return (
        <Modal show={true} className={'game-popup'} backdropClassName={'chat-modal-backdrop'}>
            <Modal.Body className={styles['popup-body']}>
            <div className={styles['popup-question']}><h5>{stepContent.title}</h5></div>
            <div className={'d-block'}>
                <label className={'textfield ' + styles.inputContainer}>
                    <div>
                        <input id="message-input" type="text" className={styles['popup-input']} value={message}
                               onChange={(e: any) => setMessage(e.target.value)}></input>
                    </div>
                </label>
                <Options stepController={stepController} extra={{message}} correct={correct}/>
            </div>
        </Modal.Body>
        </Modal>
    )
})

export default MessageInputPopup;
