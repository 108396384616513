import { createContext, useContext } from "react";
import userStore from "./UserStore";
import fireStore from "./FireStore";

const store = {
  userStore: userStore(),
  fireStore: fireStore(),
};
store.userStore.initUser()

export const StoreContext = createContext(store);
export const useStore = () => {
  return useContext<typeof store>(StoreContext);
};
export default store;