import { makeAutoObservable, runInAction } from "mobx";
import config from '@src/lib/firebase-config';
import {
  AuthError,
  createUserWithEmailAndPassword,
  getAuth,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  signOut,
  User,
  signInAnonymously,
  GoogleAuthProvider,
  linkWithPopup,
  ProviderId,
  UserInfo,
  // RecaptchaVerifier,
} from 'firebase/auth';
import { doc, getDoc, getFirestore, onSnapshot, setDoc} from 'firebase/firestore';
import { updateProfile } from "@src/services/users";

interface UserStore {
  isInit: boolean
  loading: boolean
  error: null | AuthError
  accessToken: string
  user: {
    name: string
    point: number
    isEarphoneMode: boolean
    groupsData: any
  },
  details: any
  currentUser: null | User
  isAnonymous: boolean
  unsubUser: any
  initUser: () => void
  updateUser: (metadata: any) => Promise<null | User>
  login: (email: string, password: string) => Promise<null | User>
  logout: () => void
  initSnapshot: (uid: string) => Promise<void>
  linknToProvider: (provider: string) => Promise<void>
  me: () => Promise<void>
  guestSignIn: () => Promise<null | User>
  register: (email: string, password: string, profile: any) => Promise<null | User>
}
const ARTIFACT_PLAYERS = 'artifact-players'

const store = () => {
  const auth = getAuth(config);
  const googleProvider = new GoogleAuthProvider();
  // (window as any).recaptchaVerifier = new RecaptchaVerifier('sign-in-button', {
  //   'size': 'invisible',
  //   'callback': (response: any) => {
  //     // reCAPTCHA solved, allow signInWithPhoneNumber.
  //     // onSignInSubmit();
  //   }
  // }, auth);
  const db = getFirestore(config)
  console.log('db', db);
  return makeAutoObservable({
    isInit: false,
    loading: false,
    accessToken: '',
    error: null,
    user: null as any,
    unsubUser: null,

    initUser() {
      onAuthStateChanged(auth, async (user) => {
        runInAction(async () => {
          if (user) {
            this.accessToken = await user.getIdToken(true)
            console.log(this.accessToken)
            this.initSnapshot(user.uid)
            sessionStorage.firebaseToken = this.accessToken
          } else {
            this.isInit = true
          }
        })
      });
    },
    updateUser(metadata) {
      onAuthStateChanged(auth, async (user) => {

        function updateUserMetadata(user: any, metadata: any) {
          const userRef = doc(db, ARTIFACT_PLAYERS, user.uid);
          return setDoc(userRef, {
            ...metadata
          }, { merge: true });
        }
        if (user) {
          console.log(user.uid);

          // Assuming isEarphoneMode should be true or false based on some condition in your app
          updateUserMetadata(user, metadata)
              .then(() => {
                console.log("User metadata updated!");
              })
              .catch((error: any) => {
                console.error("Error updating user metadata:", error);
              });
        }
      });
    },
    get currentUser() {
      return auth.currentUser
    },
    get isAnonymous() {
      return this.currentUser?.providerData.length === 0
    },
    async initSnapshot(uid) {
      this.unsubUser = onSnapshot(doc(db, ARTIFACT_PLAYERS, uid), async (docSnap) => {
        if (docSnap.exists()) {
          const { name, point, groupsRef, isEarphoneMode } = docSnap.data()
          const groups = await Promise.all(groupsRef.map((x: any) => getDoc(x)))
          const groupsData = groups.map((x: any) => { return { data: x.data(), id: x.id } })
          runInAction(() => {
            this.user = { name, point, groupsData, isEarphoneMode }
            this.isInit = true
          })
        }
      });
    },
    async linknToProvider(provider: string) {
      if (!auth.currentUser) return
      switch (provider) {
        case 'google': {
          linkWithPopup(auth.currentUser, googleProvider).then((result) => {
            const credential = GoogleAuthProvider.credentialFromResult(result);
            const user = result.user;
            console.log(credential)
            console.log(user)
          }).catch((error) => {
            switch (error.code) {
              case 'auth/credential-already-in-use':
              case 'auth/email-already-in-use':
              default:
                alert(error.message)
                break;
            }
          });
        }
      }
    },
    async register(
      email: string,
      password: string,
      profile: {
        firstName: string,
        lastName: string,
        dob: string,
      },
    ) {
      this.loading = true
      try {
        await createUserWithEmailAndPassword(
          auth,
          email,
          password
        );
        await updateProfile(profile);
      } catch (err) {
        this.error = err as AuthError
      } finally {
        this.loading = false
      }
      return this.currentUser
    },
    logout() {
      signOut(auth);
    },
    async guestSignIn() {
      this.loading = true
      try {
        await signInAnonymously(auth)
      } catch (err) {
        this.error = err as AuthError
      } finally {
        this.loading = false
      }
      return this.currentUser
    },
    async login(
      email: string,
      password: string
    ) {
      this.loading = true
      try {
        await signInWithEmailAndPassword(
          auth,
          email,
          password
        );
      } catch (err) {
        this.error = err as AuthError
      } finally {
        this.loading = false
      }
      return this.currentUser
    },
  } as UserStore);
};

export default store;
