import { observer } from "mobx-react-lite";
import ReactMarkdown from 'react-markdown'
import styles from "@src/views/game/components/Step/styles.module.scss";
const FullPageText = ({ stepContent }: any) => {
  return (
    <>
      <div className={styles.ScrollSection}>
        <ReactMarkdown>{stepContent.markdown}</ReactMarkdown>
      </div>
    </>
  )
}

export default observer(FullPageText);
