import React from 'react';
import Header from '@src/components/Layout/components/Header'
import { Container } from "react-bootstrap";
import { motion } from 'framer-motion';
import BottomNav from "@src/components/Layout/components/BottomNav";

interface Layout {
  children: React.ReactNode,
  title: string,
}

const variants = {
  hidden: { opacity: 0, x: -200, y: 0 },
  enter: { opacity: 1, x: 0, y: 0 },
  exit: { opacity: 0, x: 0, y: -100 }
};

const Layout = ({ children, title }: Layout) => {
  return (
    <>
      <motion.main
        initial={{ opacity: 0}}
        animate={{ opacity: 1}}
        exit="exit"
        whileDrag={'hidden'}
        variants={variants}
        transition={{ duration: 0.5 }}
        className="
                    flex flex-col items-left w-full pt-10
                    px-8 sm:px-16 md:px-36 lg:px-52 xl:px-80 2xl:px-96
                    pt-24 h-full"
      >
        {(title !== '') &&
          <Header title={title}/>}
          <div>
            {children}
          </div>
      </motion.main>
      <BottomNav />
    </>
  )
};

export default Layout;
