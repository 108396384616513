import qs from 'qs'

type CMSQuery = {
  locale: string
  populate?: string[] | string
  fields?: string[]
}

export const getBySlug = (collection: string, slug: string, querystring: CMSQuery) => {
  const query = qs.stringify(querystring);
  return fetch(`/cms/api/slugify/slugs/${collection}/${slug}?${query}`, {
    method: 'GET',
  }).then((res) => res.json())
}
export const getCollections = (collection: string, querystring: CMSQuery) => {
  const query = qs.stringify(querystring);
  return fetch(`/cms/api/${collection}?${query}`, {
    method: 'GET',
  }).then((res) => res.json())
}