// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyA0C8bmoBdQ69YDaeroNlrD8UgHaGn-g8I",
  authDomain: "exp-is-system.firebaseapp.com",
  projectId: "exp-is-system",
  storageBucket: "exp-is-system.appspot.com",
  messagingSenderId: "952670910897",
  appId: "1:952670910897:web:d67195f10d6be94ea2a590"
};
const config = initializeApp(firebaseConfig)
// Initialize Firebase
export default config