import { Modal } from "react-bootstrap";
import styles from '../styles.module.scss';
import Options from "@src/views/game/components/Step/components/Stepper";
import { Trans } from '@lingui/macro';
import { observer } from "mobx-react-lite";

const AddFriendPopup = ({ stepContent, stepController }: any) => {
  const { name, avatar } = stepContent.relation.data.attributes
  const avatarUrl = avatar.data.attributes.url
  return (
    <Modal centered show={true} className={styles['general-popup']}>
      <Modal.Body className={styles['popup-body']}>
        <span><Trans>A New Connection!</Trans></span>
        <div className={'my-4'}>
          <img
            className={styles.aixoAvatar}
            width={120}
            height={120}
            src={avatarUrl} />
        </div>
        <div className={styles.name + ' my-0'}>【{name}】</div>
        <br />
        <div className={'mb-4'}><Trans>Add New Friend?</Trans></div>
        <Options stepController={stepController} />
      </Modal.Body>
    </Modal>
  )
}

export default observer(AddFriendPopup);