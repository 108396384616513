import { observer } from "mobx-react-lite";
import Layout from "@src/components/Layout/Layout";
import styles from "@src/views/mission/style/mission.module.scss";
import MissionDetails from "@src/views/mission/components/MissionDetails";
import { Button } from "@src/components/Buttons/Button";
import { useStore } from "@src/stores";
import { useNavigate, useParams } from "react-router-dom";
import { useRequest } from 'ahooks'
import { getBySlug } from '@src/services/pages'
import ExpectedTime from "@src/views/mission/components/ExpectedTime";
import Artifact from "@src/views/mission/components/Artifact";
import Story from "@src/views/mission/components/Story";
import Map from "@src/views/mission/components/Map";
import { generate } from "@src/services/games";
import Loader from '@src/components/Loader';

const MissionIndex = observer(({ nextStep }: any) => {
  const { userStore } = useStore();
  const navigate = useNavigate();
  const { id } = useParams();
  console.log(userStore);
  const click = async () => {
    const data = userStore.user.groupsData.find((x: any) => x.data.missionId === id)
    if (!data) {
      await generate(id as string, 'en')
      // navigate("/activation", { replace: true });
    }
    // game start
    navigate(`/game/${data.id}`);
  }
  const { data: res, loading } = useRequest(() =>
    getBySlug('mission', id as string,
      {
        locale: 'en', fields: ['title', 'subtitle', 'description', 'buttonStart', 'xpReward'],
        populate: ['expectedTime', 'keyImg', 'mascot', 'story', 'mapInfo.mapImg', 'exit']
      }))
  if (loading) return (<Loader/>)
  const { title, subtitle, description, buttonStart, keyImg, mascot, expectedTime, story, mapInfo, xpReward } = res.data.attributes
  return (
    <div className={styles.missionLayout}>
      <Layout title={'浮雲之旅 #001'}>
        <span className={styles.subtitle}>{subtitle}</span>
        <span className={styles.title}>{title}</span>
        <img
          className={styles.keyImg}
          alt={'activation'}
          src={keyImg.data.attributes.url} />
        <img
          className={styles.aixoAvatar}
          alt={'AIXO'}
          width={80}
          height={80}
          src={mascot.data.attributes.url} />
        <div className={styles.description}>{description}</div>

        <MissionDetails xpReward={xpReward}>
          <ExpectedTime item={expectedTime} />
          <Artifact />
          <Story item={story} />
          <Map item={mapInfo} />
        </MissionDetails>
        <div className={'fixed-bottom ' + styles.stickyFooter}>
          <div className={styles.icon}>
            <img src={'/icon/scroll.svg'} alt={'scroll'} />
          </div>
          <div className={'d-flex justify-content-center'}>
            <Button color={'primary'} theme={'light'} onClick={click}
              text={buttonStart} />
          </div>
        </div>
      </Layout>
    </div>
  )
}
);

export default MissionIndex;
