import styles from "@src/views/game/components/Step/styles.module.scss";
import { Trans } from '@lingui/macro';
import { useParams } from "react-router";
import { observer } from "mobx-react-lite";

const GenerativeArt = ({ stepContent, stepController }: any) => {
  const { id: groupId } = useParams()
  return (
    <>
      <div className={styles.ScrollSection}>
        <div className={'my-3 text-center'}>
          <h5>{stepContent.title}</h5>
          <h5>{stepContent.description}</h5>
        </div>
        <div className={styles.center}>
          <div className={styles.postcard}>
            <iframe id={"gererativeArt"} src={`/generative/${stepContent.artScript}/?s=${groupId}.jpeg&w=${window?.screen.width}&h=${window?.screen.height}`} width={`100%`} height={`100%`} title="Gen Art" scrolling="no" ></iframe>
          </div>
        </div>
        <div className={'my-3 text-center d-flex justify-content-center'}>
          <div className={styles.circleButtons} onClick={() => {
            (document as any).getElementById('gererativeArt').contentWindow.savep5inparent()
          }}>
            <img src={'/icon/download.svg'} alt={'download'} /> <Trans>Download</Trans>
          </div>
        </div>
      </div>
    </>
  )
}

export default observer(GenerativeArt);
