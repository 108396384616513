import { Modal } from "react-bootstrap";
import styles from '../styles.module.scss';
import { useNavigate } from "react-router-dom";
import { useStore } from "@src/stores";
import { observer } from 'mobx-react-lite';
import get from 'lodash/get'
import { Button } from "@src/components/Buttons/Button";

const LeaveChatPopup = observer(({ open, setOpen, pushMode }: any) => {
  const { fireStore } = useStore();
  const { exit } = fireStore.gameResources
  const navigate = useNavigate();

  return (
    <Modal centered show={open}>
      <Modal.Body className={styles['popup-body']} style={{ height: '50vh' }}>
        <div className={'my-3'}>{get(exit, 'title')}</div>
        {/*<div>
                    <img
                        alt={'AIXO'}
                        width={108}
                        height={108}
                        src={'https://storage.googleapis.com/artifacts-cms/Mokun_Mokun_Speak_Edge_5b449dd1ca/Mokun_Mokun_Speak_Edge_5b449dd1ca.gif?updated_at=2022-08-23T15:09:11.089Z'}/>
                </div>*/}
        <div style={{ fontWeight: '600', height: '10vh' }}>
          {get(exit, 'sceneARContent')}
        </div>

        <div className={'d-flex justify-content-center btn-group-vertical'}>
          <Button className={`$(styles['popup-btn']) mb-1`} color={'primary'} theme={'dark'} onClick={() => {
            if (pushMode)
              fireStore.pushGameMode('remote')
            else {
              navigate(`?new=x`)
              window.location.reload();
            }
          }} text={get(exit, 'exitConfirm')} />
          <Button className={styles['popup-btn-alt']} color={'secondary'} theme={'light'} onClick={() => setOpen(false)}
            text={get(exit, 'exitCancel')} />
        </div>
      </Modal.Body>
    </Modal>
  )
})

export default LeaveChatPopup;
