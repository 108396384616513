import { observer } from "mobx-react-lite";
import { useStore } from "@src/stores";
import Step from "@src/views/game/components/Step";
import GameLayout from "@src/components/Layout/GameLayout";

const TabulaRasa =() => {
  const { fireStore } = useStore();
  const { stepFlow } = fireStore.currentScence
  return (
    <GameLayout theme={'minimal'}>
      <Step step={stepFlow[0]} />
    </GameLayout>
  )
}

export default observer(TabulaRasa);