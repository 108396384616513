import {lazy} from "react";
const Activation = lazy(()=> import('@src/views/activation'));
const Register = lazy(()=> import('@src/views/register'));
// import Activation from '@src/views/activation';
// import Register from '@src/views/register';

export const publicRoutes = [
  {
    path: '/login',
    element: <Register isOpen={true}/>,
  },
  {
    path: '/activation',
    element: <Activation />,
  },
  {
    path: '/*',
    element: <Activation />,
  }
];