import Layout from "@src/components/Layout/Layout";
import styles from "./styles.module.scss";
import {Container, Tabs, Tab} from "react-bootstrap";
import {useRequest} from "ahooks";
import {getBySlug} from "@src/services/pages";

const CollectionsMission = () => {
    const {data: res, loading} = useRequest(() =>
        getBySlug('mission', 'sanbao' as string,
            {
                locale: 'en', fields: ['title', 'subtitle', 'introduction', 'buttonStart', 'xpReward'],
                populate: ['artifact.item.gallery', 'artifact.character.gallery', 'artifact.memories.keyImage', 'artifact.character.keyImage', 'artifact.character.avatar', 'artifact.character.modal3D', 'artifact.knowledges.keyImage']
            }))
    if (loading) return null;
    const {artifact, title, subtitle} = res.data.attributes;
    return (
        <Layout title={title}>
            <Tabs
                defaultActiveKey="圖鑑"
                className={styles.tab}
            >
                <Tab eventKey="圖鑑" title="圖鑑">
                    {/*<Sonnet />*/}
                </Tab>
                <Tab eventKey="地圖" title="地圖">
                    {/*<Sonnet />*/}
                </Tab>
                <Tab eventKey="年史" title="年史">
                    {/*<Sonnet />*/}
                </Tab>
            </Tabs>
            <Container style={{marginBottom: '100px'}} className={'d-block text-center'}>
                <img
                    src={'https://storage.googleapis.com/artifacts-cms/2_1_3b0460e6ef/2_1_3b0460e6ef.png?updated_at=2022-09-20T11:13:59.338Z'}/>
                <h1 className={styles.title}>魂</h1>
                <img
                    className={styles.number}
                    src={'https://storage.googleapis.com/artifacts-cms/14_266fe58cb2/14_266fe58cb2.png?updated_at=2022-09-28T10:36:20.950Z'}/>

                <div className={'row'}>
                    <div className={styles.description}> 有故事的靈魂，能透過緣之鏡與TA互動</div>
                </div>
                {/*<div className={'d-flex justify-content-center'}>*/}
                {/*    <div className={styles.empty}>暫時空空如也，可以在任務中找尋回憶！</div>*/}
                {/*</div>*/}
                {artifact.character.data.map((el:any)=> {
                    console.log(el);
                    const avatarData = el?.attributes.avatar.data.attributes
                    return (
                        // null
                        <img key={avatarData.caption} src={avatarData.url} alt={avatarData.alternativeText}/>
                    )
                })}
                <hr style={{color: 'rgba(0, 0, 0, 0.2)'}}/>
                <img
                    src={'https://storage.googleapis.com/artifacts-cms/2_1_3b0460e6ef/2_1_3b0460e6ef.png?updated_at=2022-09-20T11:13:59.338Z'}/>
                <h1 className={styles.title}>物</h1>
                <img
                    className={styles.number}
                    src={'https://storage.googleapis.com/artifacts-cms/14_266fe58cb2/14_266fe58cb2.png?updated_at=2022-09-28T10:36:20.950Z'}/>
                <div className={'row'}>
                    <div className={styles.description}>緣之物 - 有紀念意義的物件</div>
                </div>
                {artifact.item.data[0].attributes.gallery.data.map((el:any)=> {
                    console.log(el);
                    const avatarData = el?.attributes
                    return (
                        <img key={avatarData.caption} src={avatarData.url} alt={avatarData.alternativeText}/>
                    )
                })}
                <div className={styles['row-wrapper']}>
                    <div className={'col-4'}>
                        <img
                            width={120}
                            src={'https://storage.googleapis.com/artifacts-cms/small_artifact_515447d533/small_artifact_515447d533.png?updated_at=2022-09-20T10:50:25.992Z'}/>
                        名稱名稱名稱
                        三保松原
                    </div>
                    <div className={'col-4'}>
                        <img
                            width={120}
                            src={'https://storage.googleapis.com/artifacts-cms/small_artifact_515447d533/small_artifact_515447d533.png?updated_at=2022-09-20T10:50:25.992Z'}/>
                        名稱名稱名稱
                        三保松原
                    </div>
                    <div className={'col-4'}>
                        <img
                            width={120}
                            src={'https://storage.googleapis.com/artifacts-cms/small_artifact_515447d533/small_artifact_515447d533.png?updated_at=2022-09-20T10:50:25.992Z'}/>
                        名稱名稱名稱
                        三保松原
                    </div>
                </div>
                {/*<div className={'d-flex justify-content-center'}>*/}
                {/*    /!*<div className={styles.empty}>暫時空空如也，可以在任務中找尋回憶！</div>*!/*/}
                {/*</div>*/}
                <hr style={{color: 'rgba(0, 0, 0, 0.2)'}}/>
                <img
                    src={'https://storage.googleapis.com/artifacts-cms/2_1_3b0460e6ef/2_1_3b0460e6ef.png?updated_at=2022-09-20T11:13:59.338Z'}/>
                <h1 className={styles.title}>憶</h1>
                <img
                    className={styles.number}
                    src={'https://storage.googleapis.com/artifacts-cms/14_266fe58cb2/14_266fe58cb2.png?updated_at=2022-09-28T10:36:20.950Z'}/>
                <div className={'row'}>
                    <div className={styles.description}>時空記憶碎片 - 銘記重要時刻</div>
                </div>
                <div className={'d-flex justify-content-center'}>
                    <div className={styles.empty}>暫時空空如也，可以在任務中找尋回憶！</div>
                </div>
                <hr style={{color: 'rgba(0, 0, 0, 0.2)'}}/>
                <img
                    src={'https://storage.googleapis.com/artifacts-cms/2_1_3b0460e6ef/2_1_3b0460e6ef.png?updated_at=2022-09-20T11:13:59.338Z'}/>
                <h1 className={styles.title}>識</h1>
                <img
                    className={styles.number}
                    src={'https://storage.googleapis.com/artifacts-cms/14_266fe58cb2/14_266fe58cb2.png?updated_at=2022-09-28T10:36:20.950Z'}/>
                <div className={'row'}>
                    <div className={styles.description}>知識書籤 - 隨地掉落的知識點</div>
                </div>
                <div className={'d-flex justify-content-center'}>
                    <div className={styles.empty}>暫時空空如也，可以在地圖中找尋書籤影子！</div>
                </div>
                <hr style={{color: 'rgba(0, 0, 0, 0.2)'}}/>
            </Container>
            {/*<BottomNav/>*/}
        </Layout>
    )

}

export default CollectionsMission;