'use strict';
import React, {memo} from 'react';
import {Formik, Field, Form} from "formik";
import styles from "@src/views/home/components/greeting/styles.module.scss";
import {activate} from '@src/services/games';

export default memo((item: any) => {
    return (
        <div className={'text-center'}>
            <div className={styles.bg}>
                <img src={'/bg-rectangle.png'} alt={'rectangle'}/>
            </div>
            <img
                className={styles.aixoAvatar}
                alt={'AIXO'}
                width={100}
                height={100}
                src={'https://storage.googleapis.com/artifacts-cms/Mokun_Mokun_Speak_Edge_5b449dd1ca/Mokun_Mokun_Speak_Edge_5b449dd1ca.gif?updated_at=2022-08-23T15:09:11.089Z'}/>
            <div className={styles.title1}>你好, [Username]</div>

            <div className={styles.title2}>歡迎回到 AIXO 空間</div>
            <div className={styles['horizontal-div-line']}/>
            <div className={styles.title3}>
                快點挑選你想要的旅程
                <br/>
                或者直接輸入啟動碼
            </div>
            <Formik
                initialValues={{code: ''}}
                onSubmit={async (values, actions) => {
                    const result = await activate(values.code)
                    actions.setSubmitting(false);
                    alert(JSON.stringify(result))
                }}
            >
                {({isSubmitting}) => (
                    <Form>
                        <div className={'row justify-content-sm-center position-relative'}>
                            <div className={'col-9 col-sm-3 col-xl-2 pe-0 mt-3'}>
                                <Field
                                    id="code"
                                    name="code"
                                    placeholder="請輸入啟動碼"
                                    type="text"
                                    className={styles.input}
                                />
                            </div>
                            <div className={'col-2  col-sm-1 col-xl-1 mt-3'}>
                                <button type="submit" className={styles.activateBtn} disabled={isSubmitting}>
                                    <img className={'p-2'} src={'/icon/arrow-right-light.svg'} alt={'arrow-right'}/>
                                </button>
                            </div>
                        </div>
                    </Form>)}
            </Formik>
        </div>

    )
})
