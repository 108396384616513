import { useRequest } from 'ahooks';
import { getCollections } from '@src/services/pages';
import Header from "@src/views/home/components/header";
import Greeting from "@src/views/home/components/greeting";
import Mission from "@src/views/home/components/Mission";
import Layout from "@src/components/Layout/Layout";
import Loader from "@src/components/Loader";

export default () => {
  const { data, loading } = useRequest(() => getCollections('home', { locale: 'en', populate: ['header'] }));
  if (loading) return <Loader />
  return (
    <Layout title={''}>
      <div style={{ marginBottom: '200px' }}>
        <Header item={data.data.attributes.header} />
        <Greeting />
        <Mission />
      </div>
    </Layout>
  )
}
