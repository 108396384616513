import 'material-symbols';
import styles from './style.module.css'
import styles2 from "@src/views/mission/style/mission.module.scss";
import {useStore} from "@src/stores";
import {useNavigate} from 'react-router';
import {observer} from "mobx-react-lite";
import {useRequest} from 'ahooks';
import {getCollections} from '@src/services/pages';
import {Badge} from "react-bootstrap";
import Slider from "react-slick";
import {useRef} from 'react';

export default observer((item: any) => {
    const {userStore} = useStore();
    const navigate = useNavigate()

    const onClick = (id: string) => {
        navigate(`/mission/${id}`, {replace: true});
    }
    const {data: res, loading} =
        useRequest(() => getCollections('missions',
            {
                locale: 'en',
                fields: ['title', 'subtitle', 'mission_id']
                , populate: ['keyImg']
            }));
    if (loading) return null

    // const sliderRef = useRef(null);
    const settings = {
        dots: false,
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        initialSlide: 1,
        arrows: false,
        responsive: [
            {
                breakpoint: 530,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                }
            },
     ]
    };
    return (
        <div className={'d-block'}>
            <div className={styles2.wrapper}>
                <div className={'p-3'}>
                    <span className={'mt-3'}>未來旅程</span>
                </div>
                <div className={styles2.second}>
                    <Slider
                        {...settings}>
                        {res.data.map(({attributes, id}: any) => {
                            const {title, subtitle, keyImg} = attributes
                            return (
                                <div key={'challenge-future' + id} onClick={() => onClick(attributes.mission_id)}>
                                    <div className={`flex-col ${styles['page']}`}>
                                        <div className={styles.location}>
                                            <img
                                                src={'/icon/map-pin.svg'}
                                                // src={keyImg.data.attributes.url}
                                                className={`${styles['image']}`}
                                            />
                                            <span className={`${styles['text']}`}>{subtitle}</span>
                                        </div>

                                        {/*</div>*/}
                                        <span className={`${styles['text_1']}`}>{title}</span>
                                        <span className={`${styles['text_2']}`}>45-90分鐘</span>
                                        <div className={`flex-col items-center ${styles['text-wrapper']}`}>
                                            <span className={`${styles['text_3']}`}>熱賣中</span>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </Slider>
                </div>
                <div className={'p-3'}>
                    <span>最新消息</span>
                </div>
                <div className={styles2.second + ' p-2'}>
                    消息標題消息標題
                </div>
                <div className={styles2.fifth + ' p-2'}>
                    消息標題消息標題
                </div>
                <div className={styles2.sixth}>
                    消息標題消息標題
                </div>
            </div>
        </div>
    )
});
