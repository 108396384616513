import { observer } from "mobx-react-lite";
import styles from "@src/views/mission/style/mission.module.scss";

const Story = observer(({ item }: any) => {
  const { header, description } = item
  return (
    <>
      <div className={'p-4'}>
        <span>{header}</span>
      </div>
      <div className={styles.second + ' p-2'}>
        {description}
      </div>
    </>
  )
}
);

export default Story;
