import { observer } from "mobx-react-lite";
import AddFriendPopup from "@src/views/game/components/Step/AddFriend";
import ArtifactPopup from "@src/views/game/components/Step/Artifact";
import Reward from "@src/views/game/components/Step/Reward";
import TakingPhoto from "@src/views/game/components/Step/TakingPhoto";
import GeneralPopup from "@src/views/game/components/Step/General";
import QuestionsPopup from "@src/views/game/components/Step/Questions";
import MessageResponsePopup from "@src/views/game/components/Step/MessageResponse"
import Explore from "@src/views/game/components/Step/Explore";
import MessageInputPopup from "@src/views/game/components/Step/MessageInput";
import ConversationPopup from "@src/views/game/components/Step/Conversation";
import TutorialPopup from "@src/views/game/components/Step/Tutorial";
import Summary from "@src/views/game/components/Step/Summary";
import Poster from "@src/views/game/components/Step/Poster";
import Congratulations from "@src/views/game/components/Step/Congratulations";
import FullPageText from "@src/views/game/components/Step/FullPageText";
import FullPageButton from "@src/views/game/components/Step/FullPageButton";
import Cutscene from "@src/views/game/components/Step/Cutscene";
import GenerativeArt from "@src/views/game/components/Step/GenerativeArt";
import AR from "@src/views/game/components/Step/AR";
import Meditation from "@src/views/game/components/Step/Meditation";
import Coupon from "@src/views/game/components/Step/Coupon";

const Step = ({ step, type }: any) => {
  if(!step) return <></>
  console.log('.step')
  const { stepInfo, stepContent, stepController, __component } = step
  const {points} = stepInfo
  console.log(__component)
  switch (__component) {
    case 'game.step-summary':
      return (<Summary stepContent={stepContent} stepController={stepController}/>)
    case 'game.step-congratulations':
      return (<Congratulations stepContent={stepContent}/>)
    case 'game.step-poster':
      return (<Poster stepContent={stepContent} stepController={stepController}/>)
    case 'game.step-full-page-text':
      return (<FullPageText stepContent={stepContent}/>)
    case 'game.step-artifact-character':
    case 'game.step-artifact-item':
    case 'game.step-artifact-memory':
    case 'game.step-artifact-knowledge':
      return (<ArtifactPopup points={points} stepContent={stepContent} type={__component} stepController={stepController}/>)
    case 'game.step-reward':
      return (<Reward stepContent={stepContent} stepController={stepController}/>)
    case 'game.step-add-friend':
      return (<AddFriendPopup stepContent={stepContent} stepController={stepController}/>)
    case 'game.step-remind':
      return (<GeneralPopup stepContent={stepContent} stepController={stepController}/>)
    case 'game.step-trivia':
      return (<QuestionsPopup stepContent={stepContent} stepController={stepController}/>)
    case 'game.step-message-response':
    case 'game.step-message-response-fork':
      // return (<ConversationPopup stepContent={stepContent} stepController={stepController}/>)
      return (<MessageResponsePopup stepContent={stepContent} stepController={stepController}/>)
    case 'game.step-message-input':
      return (<MessageInputPopup stepContent={stepContent} stepController={stepController}/>)
    case 'game.step-explore':
      return (<Explore type={type}/>)
    case 'game.step-chat':
      return (<ConversationPopup stepContent={stepContent} stepController={stepController}/>)
    case 'game.step-tutorial':
      return (<TutorialPopup stepContent={stepContent} stepController={stepController}/>)
    case 'game.step-taking-photo':
      return (<TakingPhoto stepContent={stepContent} stepController={stepController}/>)
    case 'game.step-cutscene':
      return (<Cutscene stepContent={stepContent} stepController={stepController}/>)
    case 'game.step-generative-art':
      return (<GenerativeArt stepContent={stepContent} stepController={stepController}/>)
    case 'game.step-full-page-button':
    case 'game.step-page-button':
      return (<FullPageButton stepContent={stepContent}/>)
    case 'game.start-ar-image-tracking':
    case 'game.start-ar-marker':
    case 'game.start-ar-geolocation':
      return (<AR type={__component} stepContent={stepContent} stepController={stepController}/>)
    case 'game.start-meditation':
      return (<Meditation stepContent={stepContent} stepController={stepController}/>)
    case 'game.step-coupon':
      return(<Coupon stepContent={stepContent} stepController={stepController}/>)
    default:
      break;
  }
  return (
    <></>
  )
}

export default observer(Step);
