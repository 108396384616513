import {Modal} from "react-bootstrap";
import styles from '../styles.module.scss';
import Options from "@src/views/game/components/Step/components/Stepper";

const GeneralPopup = ({stepContent, stepController}: any) => {
  const { title, avatar, content } = stepContent
  const avatarUrl = avatar.data.attributes.url
    return (
        <Modal centered show={true} className={styles['general-popup']}>
            <Modal.Body className={styles['popup-body']}>
                <span>{title}</span>
                <div>
                    <img
                        className={styles.aixoAvatar}
                        alt={'AIXO'}
                        width={208}
                        height={208}
                        src={avatarUrl}/>
                </div>
                <span>{content}</span>
                {/* <div className={'d-flex justify-content-center'}>
                    <div className={styles['popup-btn']} onClick={()=> setShow(false)}>
                       <div>好的，進入聊天室</div>
                    </div>
                </div> */}
                <Options stepController={stepController} />
            </Modal.Body>
        </Modal>
    )
}

export default GeneralPopup;