import React, {useEffect, useState} from 'react';
import {Modal, Form} from "react-bootstrap";
import {useNavigate} from "react-router-dom";
import LeaveChatPopup from "@src/views/game/components/Step/Warning";
import {observer} from 'mobx-react-lite';
import {useStore} from '@src/stores';
import style from './style.module.scss';
import {Trans} from '@lingui/macro';
import {useParams} from "react-router-dom";
import {updateStepper} from '@src/services/games';
import {updateProfile} from "@src/services/users";

// import { Transition } from 'react-transition-group';

const Sidebar = observer(({isOpen, setIsOpen}: any) => {
    const navigate = useNavigate();
    const {fireStore,userStore} = useStore();
    const {stepFlow, type} = fireStore.currentScence
    const [open, setOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [count, setCount] = useState(0);
    const {id: groupId} = useParams();
    const isEarphoneMode = userStore.user?.isEarphoneMode ?? true;
    // const FADE_DURATION = 200;
    // const fadeStyles = {
    //     'entering': 'show',
    //     'entered': 'show',
    // };
    // const Fade = ({ children, ...props }: any) => (
    //     <Transition {...props} timeout={FADE_DURATION}>
    //         {(status: string, innerProps: any) =>
    //             React.cloneElement(children, {
    //                 ...innerProps,
    //                 className: `${style.fade} ${fadeStyles.entered} ${children.props.className}`,
    //             })
    //         }
    //     </Transition>
    // );

    const {exit} = fireStore.gameResources
    return (
        <>
            <Modal
                show={!!isOpen}
                fullscreen={true}
                className={'sidebar left'}
                style={{zIndex: 1057}}
                // animation={false}
                onHide={() => setIsOpen(false)}>
                {/*<span className={'sidebar-title m-3'}> ARtefacts Logo </span>*/}
                <div className={'w-100 h-100 text-center grid-parent'}>
                    <div className={'bordered-grid-item'}>
                        <div className={'px-3'}></div>
                        <div className={'px-2'}></div>
                    </div>
                    <div className={'bordered-grid-item d-block others'}>
                        {/*<div className={'p-2 d-block text-start'}>
                            <img src={'/icon/settings.svg'} alt={'settings'}/>
                            <span className={'ms-2'}>設定</span>
                        </div>
                        <hr/>*/}
                        {/*<div className={'p-2 d-block text-start'}>
                            <img src={'/icon/help-circle.svg'} alt={'help-circle'}/>
                            <span className={'ms-2'}>常見問題</span>
                        </div>
                        <hr/>*/}
                        <div className={'p-2 d-block text-start'} onClick={() => {
                            setCount(count + 1)
                        }}>
                            <img src={'/icon/help-circle.svg'} alt={'help-circle'}/>
                            <span className={'ms-2'}><Trans>Contact HQ for Help</Trans></span>
                            <br/>
                            <span className={'service'}><Trans>Operational Hours</Trans><br/>9:00 - 18:00 (HKT)<br/>9:00 - 18:00 (JPT)</span>
                        </div>
                        <hr/>
                        <div className={'p-2 d-block text-start'}>
                            <Form.Check
                                type="switch"
                                label="Headphone Mode"
                                checked={isEarphoneMode}
                                // onChange={()=> await updateProfile(profile);({ ...fireStore.localResources, isNotEarphoneMode: !localResources?.isNotEarphoneMode })}
                                onChange={()=> userStore.updateUser({isEarphoneMode: !isEarphoneMode})}
                            />
                            <br/>
                            <span className={'service'}> Please disable noise cancellation</span>
                        </div>
                        {/* <div className={'p-2 d-block text-start clickable'} onClick={() => {
                            window.open("tel:+1 857 302 0323")
                        }}>
                            <img src={'/icon/message-square.svg'} alt={'message-square'}/>
                            <span className={'ms-2'}>English</span>
                        </div>
                        <hr/>
                        <div className={'p-2 d-block text-start clickable'} onClick={() => {
                            window.open("tel:+852 9604 6049")
                        }}>
                            <img src={'/icon/message-square.svg'} alt={'message-square'}/>
                            <span className={'ms-2'}>中文</span>
                        </div>
                        <hr/>
                        <div className={'p-2 d-block text-start clickable'} onClick={() => {
                            window.open("tel:+81 50 5532 2884")
                        }}>
                            <img src={'/icon/message-square.svg'} alt={'message-square'}/>
                            <span className={'ms-2'}>日本語</span>
                        </div> */}
                        {count > 5 && <div className={'p-2 d-block text-start clickable'} onClick={() => {
                            if (stepFlow[0].__component === 'game.step-explore') {
                                updateStepper(groupId as string, stepFlow[0].stepContent.main[0].key, {skipScene: true})
                            } else {
                                updateStepper(groupId as string, stepFlow[0].stepController[0].id, {skipScene: true})
                            }
                        }}>
                            {/* <img src={'/icon/message-square.svg'} alt={'message-square'}/> */}
                            <span className={'ms-2'}><Trans>Skip anyway</Trans></span>
                        </div>}
                        <hr/>


                        <div className={'exit d-block text-start clickable'} onClick={() => {
                            setIsOpen(false)
                            setOpen(true)
                        }}>
                            <hr/>
                            <img className={'px-2'} src={'/icon/log-out.svg'} alt={'log-out'}/>
                            <span className={'ms-2'}><Trans>Quit Mission</Trans></span>
                        </div>
                    </div>
                </div>
            </Modal>
            <LeaveChatPopup open={open} setOpen={setOpen} content={exit.missionContent} pushMode={false}/>

        </>
    )

});

export default Sidebar;
