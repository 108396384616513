import React, {FC, useEffect, useRef, useState} from 'react';
import styles from './styles.module.scss';
import mapboxgl from 'mapbox-gl/dist/mapbox-gl-csp';
import MapboxWorker from 'worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker';
import 'mapbox-gl/dist/mapbox-gl.css';
import {observer, useLocalObservable} from "mobx-react-lite";
import {createRoot} from 'react-dom/client';
import Image from 'react-bootstrap/Image'
import {Button} from "@src/components/Buttons/Button";
import {runInAction, toJS} from 'mobx';
import GameLayout from "@src/components/Layout/GameLayout";
import {i18n} from "@lingui/core"
import {updateStepper} from '@src/services/games';
import {useParams} from 'react-router';
import get from 'lodash/get'
import {onClickHandle} from '../../Step/components/Stepper';
import * as turf from '@turf/turf'
import Markdown from 'react-markdown'
import SlideButton from 'react-swipezor';
import {SlateToReact} from '@slate-serializers/react'

const mapStyle = {
    height: '100vh',
    width: '100vw'
}

function LinkRenderer(props: any) {
    return (
        <a href={props.href} target="_blank" rel="noreferrer">
            {props.children}
        </a>
    );
}

const dashArraySequence = [
    [0, 4, 3],
    [0.5, 4, 2.5],
    [1, 4, 2],
    [1.5, 4, 1.5],
    [2, 4, 1],
    [2.5, 4, 0.5],
    [3, 4, 0],
    [0, 0.5, 3, 3.5],
    [0, 1, 3, 3],
    [0, 1.5, 3, 2.5],
    [0, 2, 3, 2],
    [0, 2.5, 3, 1.5],
    [0, 3, 3, 1],
    [0, 3.5, 3, 0.5]
];

import {useLongPress} from 'use-long-press';
import {useStore} from '@src/stores';
import useSound from "use-sound";

const iconAdapter = (type: string) => {
    const map = {
        memory: '/artifacts/koto-icon.svg',
        item: '/artifacts/mono-icon.svg',
        knowledge: 'shiki-icon.svg',
        character: '/artifacts/hito-icon.svg',
    }
    if (type) return (<Image
        src={(map as any)[type]}
        fluid
    />)
    return <></>
}
const useMapBox = (config: any, stepExplore: any) => {
    const {accessToken, styleUrl, lat: defaultLatitude, lng: defaultLongitude, zoom: defaultZoom} = config.map
    const {latitude: lat, longitude: lng, zoom} = stepExplore
    mapboxgl.workerClass = MapboxWorker
    mapboxgl.accessToken = accessToken
    const bind = useLongPress((event, {context}) => {
        console.log('Long press released');
        if (typeof context === "function") {
            (context as any)()
        }
    }, {
        threshold: 2000,
    });
    const data: any = useLocalObservable(() => ({
            lat: lat || defaultLatitude,
            lng: lng || defaultLongitude,
            dashStep: 0,
            distance: (lng: number, lat: number) => {
                if (data.currentGeo)
                    return turf.distance([lng, lat], data.currentGeo, {units: 'meters'}) || -1;
                else
                    return -1;
            },
            currentGeo: null,
            zoom: zoom || defaultZoom,
            currentMarkers: [],
            geo: new mapboxgl.GeolocateControl({
                positionOptions: {
                    enableHighAccuracy: true,
                },
                // When active the map will receive updates to the device's location as it changes.
                trackUserLocation: true,
                showAccuracyCircle: false,
                // Draw an arrow next to the location dot to indicate which direction the device is heading.
                showUserHeading: true,
            }),
            mapInit: (mapContainer: any) => {
                const map = new mapboxgl.Map({
                    container: mapContainer.current,
                    style: styleUrl,
                    center: [data.lng, data.lat],
                    zoom: data.zoom,
                }).addControl(data.geo, 'bottom-right')
                map.on('load', () => {
                    data.geo.trigger({
                        zoom: defaultZoom
                    })
                    data.geo.on('geolocate', (position: any) => {
                        data.currentGeo = [position.coords.longitude, position.coords.latitude]
                        console.log('A geolocate event has occurred.');
                    });
                })
                return map
            },
            insertMarker: (mapRef: any, artifact: any, onClick: any, openPopup = false) => {
                const {content, avatar, longitude, latitude, title, buttonContent, distance, completed} = artifact
                console.log(artifact)
                const el = document.createElement('div')
                el.className = styles['marker']
                el.setAttribute(
                    'style',
                    `background-image: url('${get(avatar, 'data.attributes.url', '/icon/x.png')}')`,
                );
                // make a marker for each feature and add to the map
                const popupNode = document.createElement("div")
                const popup = new mapboxgl.Popup({
                    closeOnClick: false,
                    closeOnMove: false,
                    closeButton: false,
                    anchor: 'bottom',
                }).addClassName(styles.scan) // add popups
                    .setDOMContent(popupNode)
                    .on('open', () => {
                        mapRef.current.flyTo({
                            center: {lng: longitude, lat: latitude},
                            zoom: 18,
                            duration: 2000
                        });
                        const root = createRoot(popupNode!)
                        root.render(
                           !completed &&
                            <div className={styles.popupWrapper}>
                                <h4>{title}</h4>
                                <div className={'row w-100 justify-content-center pb-2'}>
                                    {/*<p className={styles.hint}>*/}
                                    <SlateToReact node={content}/>
                                    <Markdown components={{a: LinkRenderer}}>{content}</Markdown>
                                    {/*</p>*/}
                                </div>
                                <div className={'row'}
                                     style={{width: '250px', maxWidth: '250px', display: 'inline-block'}}>
                                    <SlideButton
                                        mainText={i18n._("Slide when Arrived")}
                                        overlayText=""
                                        classList="slide-button shadow--inset"
                                        caretClassList="slide-button-caret"
                                        overlayClassList="slide-button-overlay"
                                        // color={'primary'} theme={'light'}
                                        // bind={bind(onClick)}
                                        reset={0}
                                        onSwipeDone={function () {
                                            if (distance < 0 || data.distance(longitude, latitude) < distance) {
                                                onClick()
                                            } else {
                                                marker.togglePopup()
                                                alert(i18n._("Too far from the target"))
                                                marker.togglePopup()
                                            }
                                        }}
                                    />
                                </div>
                            </div>
                        )
                    })
                const marker = new mapboxgl.Marker(el, {
                    anchor: 'center'
                })
                    .setLngLat({lng: longitude, lat: latitude})
                    .setPopup(popup)
                    .addTo(mapRef.current);
                // .on('close', () => {
                //   console.log('...')
                //   return ()=> {
                //     mapRef.current.zoomTo(15, { duration: 500 });
                //   }
                // });
                runInAction(() => {
                    data.currentMarkers.push(marker)
                    ;openPopup && marker.togglePopup()
                })
            },
            animateDashArray: (mapRef: any, timestamp: number) => {
                // Update line-dasharray using the next value in dashArraySequence. The
                // divisor in the expression `timestamp / 50` controls the animation speed.
                const newStep = Math.floor((timestamp / 50) % dashArraySequence.length);
                if (newStep !== data.dashStep) {
                    mapRef.current.setPaintProperty(
                        'pathLayerDashed',
                        'line-dasharray',
                        dashArraySequence[data.dashStep]
                    );
                    data.dashStep = newStep;
                }
                // Request the next frame of the animation.
                requestAnimationFrame(function () {
                    data.animateDashArray(mapRef, Date.now());
                });
            },
            addLine: (mapRef: any, line: any) => {
                console.log('line', line.map((item: any) => [item.longitude, item.latitude]));
                mapRef.current.addSource("pathSourceDone", {
                    type: "geojson",
                    lineMetrics: true,
                    data: {
                        type: "FeatureCollection",
                        features: [
                            {
                                type: "Feature",
                                geometry: {
                                    type: "LineString",
                                    coordinates: line.map((item: any) => [item.longitude, item.latitude]),
                                },
                            },
                        ],
                    },
                });
                mapRef.current.addLayer({
                    id: "pathLayerBase",
                    type: "line",
                    source: "pathSourceDone",
                    layout: {
                        "line-join": "round",
                        "line-cap": "round",
                    },
                    paint: {
                        "line-width": 6,
                        'line-color': 'yellow',
                        'line-opacity': 0.8
                    },
                });
                // add a line layer with line-dasharray set to the first value in dashArraySequence
                mapRef.current.addLayer({
                    id: "pathLayerDashed",
                    type: "line",
                    source: "pathSourceDone",
                    layout: {
                        "line-join": "round",
                        "line-cap": "round",
                    },
                    paint: {
                        "line-width": 6,
                        'line-color': 'black',
                        'line-opacity': 0.5,
                        'line-dasharray': [0, 4, 3]
                    },
                });
                data.animateDashArray(mapRef, Date.now());
            },
            resetMarker: () => {
                data.currentMarkers.forEach((marker: any) => {
                    marker.remove();
                });
                data.currentMarkers = []
            }
        }
    ))

    return data
}
const Mapbox: FC<any> = ({config, stepExplore, onClick}: any) => {
    const {main, side, line} = config
    const {marker: markers} = config.map
    const mapContainer: any = useRef();
    const mapRef: any = useRef();
    const {
        mapInit,
        insertMarker,
        resetMarker,
        addLine,
        animateDashArray,
        currentMarkers
    } = useMapBox(config, stepExplore)

    useEffect(() => {
        if (!mapRef.current) {
            mapRef.current = mapInit(mapContainer)
        } // initialize map only once
        resetMarker()
        mapRef.current.on("style.load", () => {
            addLine(mapRef, line);
        })
        const allMarkers = (side.length > 0 && (side.every(({completed}: any) => completed === true)) || side.length === 0)? markers.concat(main ? main : [], side ? side : []) : side
        const totalMarkers = allMarkers.length
        if (totalMarkers == 1) {
            insertMarker(mapRef, allMarkers[0], () => onClick(allMarkers[0]), true)
        } else {
            for (const marker of allMarkers) {
                insertMarker(mapRef, marker, () => onClick(marker))
            }
        }
    }, []);

    return (
        <div ref={mapContainer} style={mapStyle}/>
    )
}
const Map = observer(({resource}: any) => {
    const {fireStore, userStore} = useStore();
    const {id: groupId} = useParams()
    const {stepFlow} = fireStore.currentScence;
    const [play] = useSound(stepFlow[0].stepContent?.voiceOver);
    useEffect(() => {
        (userStore.user.isEarphoneMode || userStore.user?.isEarphoneMode === undefined) && play();
    }, [play])

    const onClick = async (marker: any) => {
        const {key: optionId} = marker
        console.log(marker)
        await onClickHandle({groupId, optionId, extra: {}})
    }
    return (
        <GameLayout theme={'minimal'}>
            <Mapbox config={resource} stepExplore={stepFlow[0].stepContent} onClick={onClick}/>
        </GameLayout>
    )
})

export default Map;
