import { useStore } from '@src/stores';
import { observer } from 'mobx-react-lite';
import { useRoutes } from 'react-router-dom';
import { protectedRoutes } from './protected';
import { publicRoutes } from './public';
import Loader from "@src/components/Loader";

export const AppRoutes = observer(() => {
  const { userStore } = useStore();
  const routes = [...publicRoutes, ...userStore.user ? protectedRoutes : []];
  const elements = useRoutes(routes);
  if (!userStore.isInit) return (<Loader />)
  return <>{elements}</>;
})
