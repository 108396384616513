import {Modal} from "react-bootstrap";
import styles from '../styles.module.scss';
import React from "react";

const InfoPopup = ({show, setShow, data}: any) => {
    const isChar = data.type === '魂';
    return (
        <Modal centered show={show} onHide={() => setShow(false)} className={styles['general-popup']}>
            {isChar && <img
                className={styles['char-img']}
                src={'https://storage.googleapis.com/artifacts-cms/1_ddf39f7b8b/1_ddf39f7b8b.png?updated_at=2022-09-27T17:13:17.357Z'}/>}
            <div className={styles['info-close']}>
                <img className={'mt-3'} src={'/icon/close-btn.svg'} alt={'close-btn'} width={28}
                     onClick={() => setShow(false)}/>
            </div>
            <div className={styles.type}>{data?.type}</div>
            <Modal.Body className={styles['popup-body-left']}>
                <div className={styles.name}>{data?.name}</div>
                <div>相遇之地 | <img src={'/icon/map-pin.svg'} width={16} alt={'map'}/>三保松原神之道</div>
                <br/>
                {/*<div className={styles.subtitle}>*/}
                {/*    第一代羽衣之松為何會消失？*/}
                {/*</div>*/}
                {/*<br/>*/}
                <div className={'mb-4'}><b>因為火山爆發！</b><br/>
                    海邊被木柵欄圍起的「羽衣之松」已經是第三代了。第一代的松樹據說在日本寶永4年（西元1707年）富士寶永山噴發時，不幸沉沒海中。第二代松樹則撐了650年，因為太衰弱而黯然退場，目前是2010
                    年更換的第三代，約200歲，柔和中帶剛強。！
                </div>
                關聯緣分 |
                <img
                    src={'https://storage.googleapis.com/artifacts-cms/Frame_33709_6159fd0d15/Frame_33709_6159fd0d15.png?updated_at=2022-10-26T09:14:08.201Z'}/>
            </Modal.Body>
            <div className={styles.buttons}>
                <div>
                    <img src={'/icon/download.svg'} alt={'download'}/>
                </div>
                <div>
                    <img src={'/icon/share-2.svg'} alt={'share-2'}/>
                </div>
                <div className={styles['mirror-icon']}>
                    <img src={'/icon/maximize.svg'} alt={'maximize'}/>{'  '}緣之鏡
                </div>
            </div>
        </Modal>

    )
}

export default InfoPopup;
