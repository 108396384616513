import {useStore} from "@src/stores";
import {observer} from "mobx-react-lite";
import {useEffect, useState} from 'react';
import {useParams} from 'react-router';
import {stepper} from '@src/services/games'
import Scene from '@src/views/game/components/Scene'
import {Trans} from '@lingui/macro';
import {useSearchParams} from "react-router-dom";
import {useNavigate} from 'react-router'
import Map from '@src/views/game/components/Mode/Map';
import AR from '@src/views/game/components/Mode/AR'
import Permission from "./components/Mode/Permission";
import {Modal} from "react-bootstrap";
import {Button} from "@src/components/Buttons/Button";
import Loader from "@src/components/Loader";

const ARTIFACT_STEPS = 'artifact-steps'
const ARTIFACT_MESSAGES = 'artifact-messages'
const ARTIFACT_RESOURCES = 'artifact-resources'
// import { Application } from 'pixi.js';
const Daemon = ({children}: any) => {
    const [blurStart, setBlurStart] = useState(Date.now())
    const [showModal, setShowModal] = useState(false)
    const navigate = useNavigate()

    const onFocus = function () {
        console.debug("Tab is in focus");
        if (Date.now() - blurStart > 3000 * 60 * 1) {
            setShowModal(true)
        }
    };
    const onBlur = function () {
        console.debug("Tab is blurred");
        setBlurStart(Date.now())
    };
    useEffect(() => {
        window.addEventListener("focus", onFocus);
        window.addEventListener("blur", onBlur);
        return () => {
            window.removeEventListener("focus", onFocus);
            window.removeEventListener("blur", onBlur);
        };
    }, [blurStart]);
    return <>
        <Modal height={'100%'} centered show={showModal}>
            <Modal.Body>
                <div>
                    <h3><Trans>You’ve been idle for a while</Trans></h3>
                    <Button
                        color={'primary'} theme={'dark'} text={<Trans>I am back</Trans>}
                        onClick={() => {
                            navigate(0);
                            setShowModal(false)
                        }}/>
                </div>
            </Modal.Body>
        </Modal>
        {children}
    </>
};

export default observer(() => {
    const {fireStore, userStore} = useStore();
    const {id} = useParams()
    const [searchParams, setSearchParams] = useSearchParams();
    const [isLoading, setIsLoading] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const navigate = useNavigate();
    const WarningPopup =()=>
        <Modal height={'100%'} centered show={isOpen}>
            <Modal.Body>
                <div>
                <h3>Please visit again.</h3>
                    <Button
                        color={'primary'} theme={'dark'} text={'Visit Again'}
                        onClick={() => {
                            navigate('/', {replace: true});
                            setIsOpen(false)
                        }}/>
                </div>
            </Modal.Body>
        </Modal>

    useEffect(() => {
        if (!fireStore.gameResources || !fireStore.currentScence || !fireStore.gameMessage)
            setIsLoading(true)
        else
            setIsLoading(false)

        const timeoutId = setTimeout(() => {
            if (isLoading) {
                setIsOpen(true)
            }
        }, 5000); // 10 seconds
        return () => clearTimeout(timeoutId);

    }, [fireStore, isLoading]);
    // const app = new Application({
    //   background: '#F4F3F0',
    //   resizeTo: window,
    // });
    const onDisconnect = () => {
        console.debug('onDisconnect')
        fireStore.pushGameMode('getPermission');
    };

    function subscribe() {
        if (id) {
            console.debug('subscribe')
            const unsubResource = fireStore.subscribeSnapshot(ARTIFACT_RESOURCES, id, (docSnap) => fireStore.setGameResources(docSnap), onDisconnect);
            const unsubSteps = fireStore.subscribeSnapshot(ARTIFACT_STEPS, id, (docSnap) => {
                if (fireStore.currentGameMode !== 'getPermission') fireStore.pushGameMode('remote');
                fireStore.setCurrentScence(docSnap);
                fireStore.loading = false;
            }, onDisconnect);
            const unsubMessages = fireStore.subscribeSnapshot(ARTIFACT_MESSAGES, id, (docSnap) => fireStore.setGameMessage(docSnap), onDisconnect);
            if (!(searchParams as any).get('new')) {
                fireStore.pushGameMode('remote');
            }
            return () => {
                console.debug('unsubscribe')
                unsubSteps();
                unsubMessages();
                unsubResource();
            };
        }
    }

    useEffect(() => {
        setSearchParams('')
        return subscribe();
    }, [id])

    if (!fireStore.gameResources || !fireStore.currentScence || !fireStore.gameMessage) return (
        isOpen?<WarningPopup/>:<Loader/>
    )




    switch (fireStore.currentGameMode) {
        case 'getPermission': {
            const data = fireStore.gameResources;
            const gameMessage = fireStore.gameMessage;
            return (
                <Daemon>
                    <Permission id={id as string} mission={data.mission} start={gameMessage.messages.length === 0}
                                callback={async () => {
                                    fireStore.pushGameMode('remote');
                                }}/>
                </Daemon>
            );
        }
        case 'map': {
            const resources = fireStore.gameResources;
            return (
                <Daemon>
                    <Map resource={resources}/>
                </Daemon>
            );
        }
        case 'ar': {
            console.log('fireStore.localResources', fireStore.localResources);
            const resources = fireStore.localResources;
            return (
                <Daemon>
                    <AR resource={resources}/>
                </Daemon>
            );
        }
        case 'remote':
        default: {
            const {type} = fireStore.currentScence;
            stepper(id as string);
            return (
                <Daemon>
                    <Scene type={type[0]}/>
                </Daemon>
            )
        }
    }

})
