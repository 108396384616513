import {Modal} from "react-bootstrap";
import styles from '../styles.module.scss';
import Options from "@src/views/game/components/Step/components/Stepper";
import Image from 'react-bootstrap/Image'
import {has} from "lodash";
import {CSSProperties, useCallback, useEffect, useState} from "react";
import useSound from "use-sound";
import {useStore} from "@src/stores";

const ConversationPopup = ({stepContent, stepController}: any) => {
    const {relation, content, image, background, glitch, voiceOver} = stepContent
    const {userStore} = useStore();
    const [play, { stop }] = useSound(voiceOver || '/sound/chat.mp3');
    const {name} = relation? relation?.data?.attributes? relation.data.attributes: relation.value: ''
    const hasBackGround = has(background, 'data.attributes.url')
    const style: CSSProperties = {
        maxWidth: '100%',
        display: 'flex',
        alignContent: 'flex-start',
        justifyContent: 'center',
        flexWrap: 'nowrap',
        overflow: 'auto',
        marginTop: '60px',
        height: 'calc(100vh - 60px)',
        ...hasBackGround ? {
            backgroundImage: `url("${background?.data?.attributes? background.data.attributes.url: background}")`,
            backgroundPosition: 'center',
            backgroundSize: 'cover',
        } : {},
    };

    const scrollToBottom = () => {
        window.scrollTo(0, document.body.scrollHeight);
    }
    useEffect(() => {
        if (voiceOver &&  (userStore.user.isEarphoneMode || userStore.user?.isEarphoneMode === undefined)) {
            play();
        }
        return () => {
            stop();
        };
    }, [play, stop, userStore.user.isEarphoneMode]);

    useEffect(() => {
        setTimeout(scrollToBottom, 100);
    }, [stepContent]);
    return (
        <div className={`${styles.stepChatOptions} chat `} style={style}>
            {/* {[...Array(glitch?5:1)].map((a,index) => */}
            {/* )} */}
            <Image
                src={image?.data?.attributes? image.data.attributes.url: image.url}
            />
            <Modal show={true} onHide={() => {
                console.log()
            }} className={'chat-popup'}
                   backdropClassName={'chat-modal-backdrop'}>
                <Modal.Body className={styles['popup-body']}>
                    <div className={'text-start'}>
                        <span className={styles['chat-name']}>{name}</span>
                        <hr className={'mt-1'}/>
                        <span>{content}</span>
                    </div>
                    <Options stepController={stepController}/>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default ConversationPopup;
