import style from "./style.module.scss";
// import { useNavigate } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { useStore } from "@src/stores";

const Explore = ({type}: any) => {
  const { fireStore } = useStore();
  return (
    <div className={type==='chat'? style['btn-wrapper-chat'] :style['btn-wrapper']}>
      <div className={'d-flex justify-content-center'}>
        <div className={style.circleButtons} onClick={() => { fireStore.pushGameMode('map') }}>
          <img src={'/icon/Map-new.svg'} alt={'map'} />
        </div>
      </div>
    </div>
  )
}
export default observer(Explore);
