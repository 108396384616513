import { observer } from "mobx-react-lite";
import { useState } from 'react';
import StepWizard from "react-step-wizard";
import style from './style.module.scss';
import { useStore } from "@src/stores";
import Options from "@src/views/game/components/Step/components/Stepper";
export default observer(({stepContent, stepController}: any) => {
  const { fireStore } = useStore();
  const [tutorial, setTutorial] = useState(true)
  const custom = {
    enterRight: 'animate__animated animate__fadeIn',
    enterLeft: 'animate__animated animate__fadeIn',
    exitRight: 'animate__animated animate__fadeOut',
    exitLeft: 'animate__animated animate__fadeOut'
  };
  const TutorialStep = ({ nextStep, i, end }: any) =>
    <div className={'d-flex justify-content-center'} onClick={() => end ? setTutorial(false) : nextStep()}>
      <div className="overlay"
        style={{ top: stepContent[i].top, left: stepContent[i].left, width: stepContent[i].width, height: stepContent[i].height }}></div>
      <img className={'overlay-arrow'} src={'/icon/arrow-right-light.svg'}
        style={
          stepContent[i].position === 'left' ? {
            top: stepContent[i].top + stepContent[i].height, left: stepContent[i].left + 40,
            transform: 'rotate(270deg)'
          } :
            stepContent[i].position === 'top' ? {
              top: stepContent[i].top - 30,
              left: stepContent[i].left + 10,
              transform: 'rotate(90deg)'
            } :
              stepContent[i].position === 'right' ? {
                top: stepContent[i].top + 30, left: stepContent[i].left + 80,
                transform: 'rotate(180deg)'
              } :
                {
                  top: stepContent[i].top + stepContent[i].height,
                  left: stepContent[i].left + 100,
                  transform: 'rotate(270deg)'
                }}
        alt={'arrow'} />
      <span className={'overlay-title text-center'}
        style={
          stepContent[i].position === 'left' ? { top: stepContent[i].top + stepContent[i].height + 40, left: stepContent[i].left - 20 } :
            stepContent[i].position === 'top' ? { top: stepContent[i].top - 160, left: stepContent[i].left - 30 } :
              stepContent[i].position === 'right' ? { top: stepContent[i].top, left: stepContent[i].left + 120 } :
                { top: stepContent[i].top + stepContent[i].height + 40, left: stepContent[i].left + 80 }}
      >{stepContent[i].title}</span>
      <span className={'overlay-subTitle'}
        style={
          stepContent[i].position === 'left' ? { top: stepContent[i].top + stepContent[i].height + 80, left: stepContent[i].left - 30 } :
            stepContent[i].position === 'top' ? {
              top: stepContent[i].top + stepContent[i].height - 200,
              left: stepContent[i].left - 70
            } :
              stepContent[i].position === 'right' ? { top: stepContent[i].top + 30, left: stepContent[i].left + 120 } :
                { top: stepContent[i].top + stepContent[i].height + 80, left: stepContent[i].left + 30 }}
      >{stepContent[i].subtitle}</span>
      <img
        alt={'AIXO'}
        width={100}
        height={100}
        style={{ marginTop: 359, zIndex: 9999 }}
        src={'https://storage.googleapis.com/artifacts-cms/Mokun_Mokun_Speak_Edge_5b449dd1ca/Mokun_Mokun_Speak_Edge_5b449dd1ca.gif?updated_at=2022-08-23T15:09:11.089Z'} />
    </div>
  return (
    <>
      {tutorial && <div className={'mask-tutorial'}>
        <StepWizard transitions={custom}>
          <TutorialStep i={0} />
          <TutorialStep i={1} />
          <TutorialStep i={2} />
          <TutorialStep i={3} />
          <TutorialStep i={4} />
          <TutorialStep i={5} end={true} />
        </StepWizard>
      </div>}
      {!tutorial && <Options stepController={stepController} />}
    </>
  )
});
