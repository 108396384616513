import { reset } from '@src/services/games'
import { observer } from "mobx-react-lite";
import { Button } from "@src/components/Buttons/Button";
import { Container, Row } from "react-bootstrap";
import { ParallaxProvider, Parallax } from "react-scroll-parallax";
import styles from './styles.module.scss';
import { i18n } from "@lingui/core"
import useSound from 'use-sound';
import { useEffect } from 'react';
import { get, has } from 'lodash';

const Permission = (({ id, callback, mission, start }: any) => {
  const [play] = useSound(get(mission,'sound.data.attributes.url'));
  const getPermission = function () {
    callback()
  }
  useEffect(() => {
    if(has(mission,'sound.data.attributes.url')) {
      play();
    }
  })
  return <>
    <ParallaxProvider>
      <Container fluid style={{ overflow: 'hidden' }}>
        <Row className={styles.missionPermissionOptions} style={{
          animation: "fadeIn 5s",
        }}>
          <div className={styles.missionInfo} style={{
            paddingTop: "5vh",
            zIndex: "2",
            position: "fixed",
            left: "0"
          }}>
            <h6 className={`mt-4`} style={{
              animation: "fadeIn 1s",
            }}>Mission</h6>
            <h1 className={`cjk ${styles.missionTitle}`} style={{
              animation: "fadeIn 2s",
              color: mission.keyColor,
            }}>{mission.title}</h1>
            <h4 style={{
              animation: "fadeIn 3s",
              color: mission.keyColor,
            }}>{mission.subtitle}</h4>

            <h6 className={`mt-4`} style={{
              animationDelay: "2s",
              animation: "fadeIn 1s",
            }}>Duration</h6>
            <h5 style={{
              animationDelay: "2s",
              animation: "fadeIn 3s",
              color: mission.keyColor,
            }}>{mission.estimatedDuration}</h5>

            <h6 className={`mt-4`} style={{
              animationDelay: "5s",
              animation: "fadeIn 1s",
            }}>Brief</h6>
            <p style={{
              animationDelay: "7s",
              animation: "fadeIn 3s",
              color: mission.keyColor,
            }}>{mission.description}</p>
          </div>
          <Parallax translateY={[-80, 80]} opacity={[1.5, -.5]} className={styles.missionBackground}>
            <div className={`glitch`}>
              <div className={`glitch__img`} style={{ backgroundImage: `url(${mission.keyImg?.formats.small.url})` }}></div>
              <div className={`glitch__img`} style={{ backgroundImage: `url(${mission.keyImg?.formats.small.url})` }}></div>
              <div className={`glitch__img`} style={{ backgroundImage: `url(${mission.keyImg?.formats.small.url})` }}></div>
              <div className={`glitch__img`} style={{ backgroundImage: `url(${mission.keyImg?.formats.small.url})` }}></div>
              <div className={`glitch__img`} style={{ backgroundImage: `url(${mission.keyImg?.formats.small.url})` }}></div>
            </div>
          </Parallax>
        </Row>
      </Container>
      <div className={styles.startButtons}>
        <Button color={'primary'} theme={start ? 'light' : 'dark'} onClick={() => getPermission()} text={start ? i18n._("start") : i18n._('continue')} />
        {/*<Button color={'secondary'} theme={'light'} onClick={async () => { const res = await reset(id as string); alert(i18n._("Reset succeed")) }} text={i18n._('reset')} /> {/*to be hidden for produciton*/}
        <br />
        <img src="/img/XO-wht.png" alt='XO' style={{
          marginTop: "60px",
          left: "50vw",
          width: "160px",
          animation: "fadeIn 8s",
        }} onClick={async () => {
          const res = await reset(id as string);
          alert(i18n._("Reset succeed"))
         }} />
      </div>
    </ParallaxProvider>
  </>
})

export default observer(Permission)
