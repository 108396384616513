import styles from "@src/views/game/components/Step/Summary/styles.module.scss";
import { observer } from "mobx-react-lite";
import get from "lodash/get";
import Options from "@src/views/game/components/Step/components/Stepper";
import { Image } from 'react-bootstrap'

const Poster = ({ stepContent, stepController }: any) => {
  const image = get(stepContent,'image.url', get(stepContent, 'image.data.attributes.url', '/finale_places.png'))

  return (
    <>
      <div className={styles.ScrollSection}>
        <Image className={styles.background} src={image} fluid />
        {stepContent.title && <h4>{stepContent.title}</h4>}
        {stepContent.description && <h5>{stepContent.description}</h5>}
        {stepController.length > 0 && <Options stepController={stepController} />}
      </div>
    </>
  )
}

export default observer(Poster);
