import {observer} from "mobx-react-lite";
import Image from "react-bootstrap/Image";
import style from './style.module.scss';

const height = (size: string) => {
    switch (size) {
        case 'xs':
            return 60
        case 'sm':
            return 100
        case 'xl':
            return 120
        default:
            return 100
    }
}

const Loader = observer(({size = 'xl', src = '/gif/Loading-(Black).gif', position = 'middle'}: any) => {
    return (
        <div className={'d-flex justify-content-center'}>
            <div style={position === 'middle' ? {
                margin: '0',
                position: 'absolute',
                top:  '50%',
                left: '50%',
                transform: 'translateY(-50%) translateX(-50%)'
            } : {
                margin: '0',
                position: 'absolute',
                bottom: '10%',
                left: '78%',
                transform: 'translateY(50%) scale(0.3)'
            }}>
                <Image src={src}
                       height={height(size)}
                       className={style.loader}/>
            </div>
        </div>
    )
})

export default Loader;
