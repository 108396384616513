import { ProgressBar } from 'react-bootstrap'
import Sidebar from "@src/components/Layout/components/Sidebar";
import { useStore } from "@src/stores";
import styles from "@src/components/Layout/components/GameHeader/styles.module.scss";
import { useLocalObservable, observer } from 'mobx-react-lite';
import LeaveChatPopup from "@src/views/game/components/Step/Warning";
import get from "lodash/get"
import { updateStepper } from '@src/services/games';
import { useParams } from 'react-router';
import { useLongPress } from 'use-long-press';
import { useEffect } from 'react';


const useHeader = () => {
  const data: any = useLocalObservable(() => ({
    isOpen: false,
    toggle: () => {
      data.isOpen = !data.isOpen
    },
  }
  ))
  return data
}
const Leave = ({isOpen, toggle}: any) => {
  return <>
    <div className={'col-2 d-flex justify-content-center clickable'}>
      <div className={'my-3'}>
        <div onClick={toggle}>
          <img src={'/icon/chevron-left.svg'} alt={'chevron-left'} />
        </div>
      </div>
    </div>
    <LeaveChatPopup open={isOpen} setOpen={toggle} pushMode={true} />
  </>
}
const More = ({isOpen,toggle}: any) => {
  return <>
    <div className={'col-2 d-flex justify-content-center clickable'} onClick={toggle}>
      <div className={'my-3'} >
        <img src={'/icon/more-vertical.svg'} alt={'more'} />
      </div>
    </div>
    <Sidebar isOpen={isOpen} setIsOpen={toggle} />
  </>
}
const Back = ({pop}: any) => {
  return <>
    <div className={'col-2 d-flex justify-content-center clickable'}>
      <div className={'my-3'}>
        <div onClick={pop}>
          <img src={'/icon/chevron-left.svg'} alt={'chevron-left'} />
        </div>
      </div>
    </div>
  </>
}
const Left = ({type, isOpen, toggle, mode, pop}: any) => {
  switch (true) {
    case mode === 'map':
      return <Back pop={pop} />
    case mode === 'ar':
      return <Leave isOpen={isOpen} toggle={toggle} />
    case type === 'scene.chat':
    default:
      return <More isOpen={isOpen} toggle={toggle} />
  }
}
const Header = ({theme}: any) => {
  const { fireStore } = useStore();
  const { stepFlow, type } = fireStore.currentScence
  const { points, progress } = fireStore.gameResources
  const { currentGameMode, popGameMode } = fireStore
  const { stepInfo } = stepFlow[0]
  const { isOpen, toggle } = useHeader()
  const { id: groupId } = useParams()
  useEffect (() => {
    window.onpopstate = (e) => { 
      e.preventDefault();
      if (currentGameMode === 'ar') {
        popGameMode()
      }
    }
  }, [])
   const bind = useLongPress(()=> skipAnyway(groupId as string, stepFlow), {
    onStart: event => console.log('Press started'),
    onFinish: event => console.log('Press finished'),
    onCancel: event => console.log('Press cancelled'),
    onMove: event => console.log('Detected mouse or touch movement'),
    filterEvents: event => true, // All events can potentially trigger long press (same as 'undefined')
    threshold: 1500, // In milliseconds
    captureEvent: true, // Event won't get cleared after React finish processing it
    cancelOnMovement: 25, // Square side size (in pixels) inside which movement won't cancel long press
    cancelOutsideElement: true, // Cancel long press when moved mouse / pointer outside element while pressing
  });
  const skipAnyway = (groupId: string, stepFlow: any) => {
    if(stepFlow[0].__component === 'game.step-explore') {
      updateStepper(groupId as string, stepFlow[0].stepContent.main[0].key, { skipScene: true })
    } else {
      updateStepper(groupId as string, stepFlow[0].stepController[0].id, { skipScene: true })
    }
  }
  return (
    <div className={`${styles[theme]} ${styles['game-header']}`} {...bind()}>
      <nav>
        <div className={'row w-100 text-center'}>
          <Left type={get(type,'0.__component')} mode={currentGameMode} isOpen={isOpen} toggle={toggle} pop={popGameMode}/>
          <div className={'col-8 title-chat'}>
            <div className={'mt-4'}>
              <ProgressBar now={progress || 0} max={100} className={styles.progressBar} />
            </div>
          </div>
          <div className={'col-2 d-flex justify-content-center title-exp'}>
            <span className={'my-3'}>{points} En</span>
          </div>
        </div>
      </nav>
    </div>
  )
};

export default observer(Header);
