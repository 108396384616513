import { Modal } from "react-bootstrap";
import styles from '../styles.module.scss';
import Image from 'react-bootstrap/Image'
import { toJS } from "mobx";
import { get, has } from "lodash";
import { Button } from "@src/components/Buttons/Button";
import {Trans} from "@lingui/macro";
import React, {useState} from "react";
const defaultIcon = '/icon/arrow-right.svg'

const StepperButton = ({ item, onClick }: any) => {
  const { title, icon, color, theme } = item
  const hasTitle = has(item, 'title') && item.title
  const showIcon = has(icon, 'data.attributes.url')
  const showDefaultIcon = !hasTitle && !showIcon
  return (
    <Button
      color={color} theme={theme} text={<>
        {showDefaultIcon && <Image src={defaultIcon} style={{ maxHeight: '100%' }} />}
        {showIcon && <Image src={get(icon, 'data.attributes.url')} style={{ maxHeight: '100%' }} />}
        {title}
      </>}
      onClick={onClick} />
  )
}
const FullPageButton = ({ stepContent }: any) => {
  const { target, url, background, title, icon, color, theme } = stepContent
  console.log(toJS(stepContent))
  const backgroundUrl = get(background,'data.attributes.url');
  const [isOpen, setIsOpen] = useState(true);
  return (
    <Modal centered show={isOpen} className={styles['general-popup']}>
      <Modal.Body className={styles['popup-body']} >
        <StepperButton key={`page-button`} loading={false} setLoading={() => {console.log('empty') }} item={{ title, icon, color, theme }} onClick={async () => {
          window.open(url, target)
        }}
        />
        <div onClick={()=> setIsOpen(false)}><Trans>close</Trans></div>
      </Modal.Body>
    </Modal>
  )
}

export default FullPageButton;
