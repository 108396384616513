import { AppRoutes } from '@src/routes'
import store, { StoreContext } from '@src/stores'
import { BrowserRouter as Router } from 'react-router-dom'
import './styles/global.scss';

import { Helmet } from "react-helmet";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { i18n } from '@lingui/core'
import { I18nProvider } from '@lingui/react'
import { messages as messagesEN  } from './locales/en/messages'
import { messages as messagesZH } from './locales/zh/messages'
import { messages as messagesJA } from './locales/ja/messages'

i18n.load('en', messagesEN)
i18n.load('zh', messagesZH)
i18n.load('ja', messagesJA)

i18n.activate('en')
function App() {
  return (
    <I18nProvider i18n={i18n}>
      <StoreContext.Provider value={store}>
        <Helmet>
          <title>PLAAAY by EXP.</title>
        </Helmet>
        <main>
          <Router>
            <AppRoutes />
          </Router>
        </main>
      </StoreContext.Provider>
    </I18nProvider>
  );
}

export default App
