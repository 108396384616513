import { Modal } from "react-bootstrap";
import { observer } from 'mobx-react-lite';
import Image from 'react-bootstrap/Image'
import {get, has} from 'lodash';
import ReactPlayer from 'react-player/vimeo'
import screenfull from 'screenfull'
import React, { useCallback, useRef } from "react";
import QuickPinchZoom, { make3dTransformValue } from "react-quick-pinch-zoom";
import styles from "@src/views/game/components/Step/styles.module.scss";
import {Trans} from "@lingui/macro";
import { saveAs } from 'file-saver'
import {updateStepper} from "@src/services/games";
import {canvasToBlob} from "blob-util";

const PopupArtifact = observer(({ open, detail, handleClose }: any) => {
  const refPlayer: any = useRef();
  const imgRef = useRef(null);
  const {download} = detail
  const onReady = () => {
    console.log('onReady')
    handleClickFullscreen()
  }
  const handleClickFullscreen = () => {
    console.log('handleClickFullscreen')
    if(screenfull.isEnabled) {
      screenfull.request(refPlayer.current.wrapper)
    }
  }

  const share = async (photo: any) => {
      try {
        const response = await fetch(photo);
        const blob = await response.blob();
        const file = new File([blob], 'image.jpg', {
          type: blob.type,
        });

        if (navigator.canShare && navigator.canShare({ files: [file] })) {
          await navigator.share({
            files: [file],
            title: "Download Image",
            text: "Here is the image you wanted to download.",
          });
          console.log("Image shared successfully");
        } else {
          // Fallback method for devices that do not support Web Share API
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.style.display = "none";
          a.href = url;
          a.download = 'yourImageFileName.jpg';
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
          document.body.removeChild(a);
          console.log("Image downloaded using fallback method");
        }
      } catch (error) {
        console.error("Download failed:", error);
      }
  }
  const handleEnded = () => {
    console.log('onEnded')
    handleClose()
    screenfull.exit()
  }
  const onUpdate = useCallback(({ x, y, scale }: any) => {
    const { current: img } = imgRef;

    if (img) {
      const value = make3dTransformValue({ x, y, scale });

      (img as any).style.setProperty("transform", value);
    }
  }, []);
  return (
    <Modal height={'60%'} centered show={open} onHide={handleClose}>
      <Modal.Body>
        {detail.type === 'video' && <ReactPlayer
          ref={refPlayer}
          onReady={onReady}
          url={get(detail, 'videoId')}
          controls={true}
          volume={0}
          muted={true}
          playing={true}
          width={'100%'}
          height={'100%'}
          playsinline={false}
          onStart={() => { handleClickFullscreen(); console.log('onStart') }}
          onEnded={handleEnded}
        />}
        {detail.type === 'picture' && <QuickPinchZoom onUpdate={onUpdate}>
      <Image fluid ref={imgRef} src={get(detail.gallery, 'data.0.attributes.url')} />
    </QuickPinchZoom>
    }
        {download &&
            <div className={'my-3 text-center d-flex justify-content-center'}>
              <div className={styles.circleButtons} onClick={async ()=> await share(get(detail.gallery, 'data.0.attributes.url'))}>
                <img src={'/icon/download.svg'} alt={'download'} />
              </div>
            </div>
        }
      </Modal.Body>
    </Modal>
  )
})

export default PopupArtifact;
