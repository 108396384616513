import React from 'react';
import GameHeader from '@src/components/Layout/components/GameHeader'
import { motion } from 'framer-motion';
import { observer } from "mobx-react-lite";

interface Layout {
  children: React.ReactNode,
  theme?: string,
  style?: any,
}

const variants = {
  hidden: { opacity: 0, x: -200, y: 0 },
  enter: { opacity: 1, x: 0, y: 0 },
  exit: { opacity: 0, x: 0, y: -100 }
}

const GameLayout = ({ children, theme = 'default', style = {}}: Layout) => {
  return (
    <div style={style}>
      <motion.main
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit="exit"
        whileDrag={'hidden'}
        variants={variants}
        transition={{ duration: 0.5 }}
        className="
                    flex flex-col items-left w-full pt-10
                    px-8 sm:px-16 md:px-36 lg:px-52 xl:px-80 2xl:px-96
                    pt-24 h-full"
      >
        <GameHeader theme={theme} />
        <div>
          {children}
        </div>
      </motion.main>
    </div>
  )
};

export default observer(GameLayout);
