import styles from "@src/components/Layout/components/BottomNav/styles.module.scss";
import {useNavigate, useLocation} from "react-router-dom";

const BottomNav = () => {
    const navigate = useNavigate();
    const location = useLocation();
    console.log(location);
    const tabs = [
        {
            href: '/home',
            icon: 'nav-2',
            label: '探索'
        },
        {
            href: '/collections',
            icon: 'nav-3',
            label: '緣之錄'
        },
        {
            href: '/e-reward',
            icon: 'nav-1',
            label: '緣之鏡'
        },
        {
            // href: '/setting',
            href: '/test',
            icon: 'nav-4',
            label: '設置 '
        }
    ];
    return (
        <nav className={styles.stickyFooter}>
            <div className={'text-center grid-parent'}>
                <div className={'fixed-bottom'}>
                    <div className={styles.icon}>
                        <img src={'/icon/scroll.svg'} alt={'scroll'}/>
                    </div>
                    <div className={styles.navbar + ' row'}>
                        {tabs.filter(Boolean).map((tab, index) => (
                            <div
                                key={index}
                                className={`col-3 flex-column add-to-cart d-flex justify-content-center align-items-center text-center 
                        ${location.pathname === tab.href ? styles.active : ''}`}
                                onClick={() => navigate(tab.href)}
                            >
                                <div className={location.pathname === tab.href ? styles.mark : ''}/>
                                <div className={'px-0 pb-1'} style={{width: '1.5rem'}}>
                                    <img src={`/icon/${tab.icon}.svg`} alt={tab.icon}/>
                                </div>
                                <span>{tab.label}</span>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </nav>
    )
}

export default BottomNav;