import { Modal } from "react-bootstrap";
import styles from '../styles.module.scss';
import Options from "@src/views/game/components/Step/components/Stepper";
import { useEffect, useState } from "react";
import { Trans } from '@lingui/macro';
import useEventListener from "ahooks/lib/useEventListener";
import Image from 'react-bootstrap/Image'

// const avatarTypeSrc = (type: any) => {
//   switch (type) {
//     case 'game.step-artifact-character':
//       return "https://storage.googleapis.com/artifacts-cms/ad8xs_0hh6n_c041564f4f";
//     case 'game.step-artifact-item':
//       return "https://storage.googleapis.com/artifacts-cms/ad8xs_0hh6n_c041564f4f";
//     case 'game.step-artifact-memory':
//       return "https://storage.googleapis.com/artifacts-cms/ad8xs_0hh6n_c041564f4f";
//     case 'game.step-artifact-knowledge':
//       return "https://storage.googleapis.com/artifacts-cms/ad8xs_0hh6n_c041564f4f";
//     default:
//       return "https://camo.githubusercontent.com/179d66ab2b0321726c88a586c4ad38802e7113a3c98c6fd3f0156c01c98cfd14/68747470733a2f2f6672616d657275736572636f6e74656e742e636f6d2f696d616765732f34386861395a52396f5a51475136675a38595566456c50335430412e706e67";
//   }
// }

const congratulatePhrase = (type: any) => {
  switch (type) {
    case 'game.step-artifact-character':
      return <Trans>You have made a new Friend!</Trans>;
    case 'game.step-artifact-item':
      return <Trans>You have picked up a new Item!</Trans>;
    case 'game.step-artifact-memory':
      return <Trans>You have collected a new Chronicle!</Trans>;
    case 'game.step-artifact-knowledge':
      return <Trans>You have earned a new Knowledge!</Trans>;
    default:
      return <Trans>Yay!</Trans>;
  }
}

const ArtifactPopup = ({ type, points, stepContent, stepController }: any) => {
    const { name, avatar } = stepContent.relation.data.attributes
  const avatarUrl = avatar?.data?.attributes?.url?  avatar.data.attributes.url: avatar.url
  const [value, setValue] = useState(0)
  const [orientation, setOrientation] = useState([0,0])
  // useEventListener(
  //   'deviceorientation',
  //   ({
  //     alpha: z,
  //     beta: x,
  //     gamma: y
  //   }) => {
  //     if(!x || !y || !z) {
  //       setOrientation([0,0])
  //       return null
  //     }
  //     const radX = Math.atan2(x,z);
  //     const degX = radX * (180 / Math.PI);
  //     const radY = Math.atan2(y,z);
  //     const degY = radY * (180 / Math.PI)
  //     setOrientation([degX,degY])
  //   }
  // );
  useEffect(() => {
    function animateValue({ start, end, duration }: any) {
      let startTimestamp: any = null;
      const step = (timestamp: any) => {
        if (!startTimestamp) startTimestamp = timestamp;
        const progress = Math.min((timestamp - startTimestamp) / duration, 1);
        setValue(Math.floor(progress * (end - start) + start))
        if (progress < 1) {
          window.requestAnimationFrame(step);
        }
      };
      window.requestAnimationFrame(step);
    }

    animateValue({ start: 0, end: points, duration: 1000 });

  }, [])

    return (
    <Modal show={true} fullscreen={true} className={`fullScreen ${styles['artifact-popup']}`} animation={false}>
      <div className={styles['top']}>
        {/*<img
          src={avatarTypeSrc(type)}
          alt={'artifact'}
          width={53} Pika-pooh?
        />*/}
      </div>
      {/*<h4 className={'mt-3'}><Trans>Congratulations!</Trans></h4>*/}
      <h6>{stepContent.congratulatePhrase || congratulatePhrase(type)}</h6>
      <div className={styles.firefly} style={{ top: '25vh', left: '20vw' }} />
      <div className={styles.firefly} style={{ top: '25vh', left: '40vw' }} />
      <div className={styles.firefly} style={{ top: '25vh', left: '50vw' }} />
      <div className={styles.firefly} style={{ top: '25vh', left: '60vw' }} />
      <div className={styles.firefly} style={{ top: '25vh', left: '80vw' }} />
      <div className={styles.firefly} style={{ top: '40vh', left: '20vw' }} />
      <div className={styles.firefly} style={{ top: '40vh', left: '40vw' }} />
      <div className={styles.firefly} style={{ top: '40vh', left: '50vw' }} />
      <div className={styles.firefly} style={{ top: '40vh', left: '60vw' }} />
      <div className={styles.firefly} style={{ top: '40vh', left: '80vw' }} />
      <h2 className={styles.exp}>+{value} en</h2>
      <div className={styles['shade-effect']} style={{
          transform: `perspective(800px) rotateX(${orientation[0]}deg) rotateY(${orientation[1]}deg)`
      }}>
        <Image
          src={avatarUrl}
          alt={'ar'}
          className={styles['artifactimg']}
          // width={300}
          // height={300}
          style={{maxWidth:'250px', height:'250px', maxHeight:'250px'}}
        />
      </div>

      <h3 className={styles['name']}>{name}</h3>
      <Options stepController={stepController} />
    </Modal>
  )
}

export default ArtifactPopup
