import { Modal } from "react-bootstrap";
import styles from '../styles.module.scss';
import { useEffect, useState } from "react";
import { Trans } from '@lingui/macro';
import { observer } from "mobx-react-lite";
import Options from "@src/views/game/components/Step/components/Stepper";
import {postCoupon, updateStepper} from "@src/services/games";
import {useParams} from "react-router-dom";
const Coupon = ({ stepContent, stepController }: any) => {
    const { id: groupId } = useParams()
    const [email, setEmail] = useState('');
    const webhook = stepContent.webhook
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const [err, setErr] = useState('')
    console.log(stepContent);
    const onClick= async({skip}: any)=> {
        try {
            const isValid = emailRegex.test(email);
            if (skip) {
                await updateStepper(groupId as string, stepController[0].id, { skipScene: true })
            } else {
                if (isValid) {
                    await postCoupon(webhook, email, stepContent)
                    await updateStepper(groupId as string, stepController[0].id, { skipScene: true })
                } else setErr('Invalid Email. Please try again')
            }

        } catch (e) {
            console.log(e)
        }
    }

    return (
        <Modal show={true} centered>
            <Modal.Body className={styles['popup-body--coupon']}>
                <div className={styles.center}>
                    <div className={styles.popupTitle}><Trans>Coupon</Trans></div>
                </div>
                <div style={{textAlign: 'center', fontWeight: 'bold', margin: '10px'}}>{stepContent.description}</div>
                <div><Trans>Enter your email:</Trans></div>
                <input id="message-input" type="text" className={styles['message-input']} value={email}
                       onChange={(e: any) => setEmail(e.target.value)}></input>
                <div className={'mb-5'}>{stepContent.title}</div>
                <Options stepController={stepController} onClick={onClick}/>
                <div style={{textAlign: 'center', color: 'red'}}>{err}</div>
                <div style={{textAlign: 'right', margin: '10px', cursor: 'pointer', textDecoration:'underline'}}>
                <a onClick={()=>onClick({skip: true})}><Trans>Skip</Trans></a>
                </div>
                {/* <Button color={'primary'} theme={'light'} onClick={() => setShow(false)} text={i18n._("Count Your Blessings")}/> */}
            </Modal.Body>
        </Modal>
    )
}

export default observer(Coupon);
