import { Modal } from "react-bootstrap";
import styles from '../styles.module.scss';
import { useEffect, useState } from "react";
import { Trans } from '@lingui/macro';
import { observer } from "mobx-react-lite";
import Options from "@src/views/game/components/Step/components/Stepper";
function animateValue({ start, end, duration, setValue }: any) {
  let startTimestamp: any = null;
  const step = (timestamp: any) => {
    if (!startTimestamp) startTimestamp = timestamp;
    const progress = Math.min((timestamp - startTimestamp) / duration, 1);
    setValue(Math.floor(progress * (end - start) + start))
    if (progress < 1) {
      window.requestAnimationFrame(step);
    }
  };
  window.requestAnimationFrame(step);
}
const Reward = ({ stepContent, stepController }: any) => {
  const [value, setValue] = useState(0)
  useEffect(() => {
    animateValue({ start: 0, end: stepContent.xp, duration: 1000, setValue });
  }, [])
  return (
    <Modal show={true}
      centered
    >
      <Modal.Body className={styles['popup-body']}>
        <div className={styles.center}>
          <div className={styles.popupTitle}><Trans>Complete</Trans>!</div>
        </div>
        <div className={styles.exp + ' my-0'}> +<span id={'value'}>{value}</span> XP</div>
        <div className={'mb-5'}>{stepContent.title}</div>
        <Options stepController={stepController} />
        {/* <Button color={'primary'} theme={'light'} onClick={() => setShow(false)} text={i18n._("Count Your Blessings")}/> */}
      </Modal.Body>
    </Modal>
  )
}

export default observer(Reward);