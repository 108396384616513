import {observer} from "mobx-react-lite";
import styles from "@src/views/mission/style/mission.module.scss";
import {Image} from 'react-bootstrap'

const Map = observer(({item}: any) => {
        const {header, mapImg} = item
        return (
            <>
                <div className={'p-3'}>
                    <span>{header}</span>
                </div>
                <div className={styles.second}>
                    <Image src={mapImg.data.attributes.url} className={styles.ImgWrapper}/>
                </div>
            </>
        )
    }
);

export default Map;
