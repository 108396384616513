import { observer } from "mobx-react-lite";
import SceneMessage from '@src/views/game/components/Scene/Message'
import SceneChat from '@src/views/game/components/Scene/Chat'
import SceneFinale from '@src/views/game/components/Scene/Finale'
import SceneCarousel from '@src/views/game/components/Scene/Carousel'
import SceneTabulaRasa from '@src/views/game/components/Scene/TabulaRasa'


export default observer(({ type }: any) => {
  console.log(type)
  switch (type.__component) {
    case 'scene.message':
      return <SceneMessage />
    case 'scene.chat':
      return <SceneChat />
    case 'scene.finale':
      return <SceneFinale />
    case 'scene.carousel':
      return <SceneCarousel />
    case 'scene.tabula-rasa':
      return <SceneTabulaRasa />
    default:
      break;
  }
  return (
    <></>
  )
}
);
