import { observer } from "mobx-react-lite";
import { useStore } from "@src/stores";
import Step from "@src/views/game/components/Step";
import Slider from "react-slick";
import React from "react";

const FinaleIndex = () => {
  const { fireStore } = useStore();
  const { stepFlow } = fireStore.currentScence

  const settings = {
    dots: true,
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 500,
    arrows: false,
    vertical: true,
    verticalSwiping: true,
  };
  return (
    <Slider {...settings}>
      {React.Children.toArray((stepFlow).map((ctx: any) => {
        return <Step step={ctx} />
      }))}
    </Slider>

  )
}

export default observer(FinaleIndex);