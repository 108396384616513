import GameLayout from "@src/components/Layout/GameLayout";
import { useStore } from "@src/stores";
import { Camera } from "react-camera-pro";
import Step from "@src/views/game/components/Step";
import { useRef, useState } from "react";

export default () => {
  const { fireStore } = useStore();
  const { stepFlow, type } = fireStore.currentScence
  const { background } = type[0]
  const camera = useRef(null);
  const [numberOfCameras, setNumberOfCamera] = useState(0);
  return (
    // <ChatLayout
    //   progress={{
    //     now: stepInfo.progress,
    //     max: 100
    //   }}
    //   exp={'100'}
    //   background={background.data.attributes.url}
    // >
    <GameLayout theme={'minimal'} >
        {/* <div style={{
          position: 'absolute',
          top: 0,
          height: '99%',
        }}> */}
          {/*<Camera
            ref={camera}
            numberOfCamerasCallback={(numberOfCameras: number) => {
              setNumberOfCamera(numberOfCameras);
            }}
            aspectRatio="cover"
            facingMode={"environment"}
            errorMessages={{
                noCameraAccessible: '',
                permissionDenied: '',
                switchCamera: '',
                canvas: '',
            }}
          />*/}
          <Step step={stepFlow[0]} type={'chat'}/>
        {/* </div> */}
    </GameLayout>
  )
};
