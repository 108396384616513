import {observer} from "mobx-react-lite";
import styles from "@src/views/mission/style/mission.module.scss";

const ExpectedTime = observer(({item}: any) => {
        const {title, subtitle, buttonContext} = item
        console.log(item)
        return (
            <>
                <div className={'p-2 ' + styles['grid-item']}>
                    <div className={'row'}>
                        <div className={'col-2 p-2'}>
                            <img src={'/icon/clock.svg'} alt={'clock'}/>
                        </div>
                        <div className={'col-10'}>
                            <span className={styles.play}>{title}</span>
                            <span className={styles.time}>{subtitle}</span>
                        </div>
                    </div>
                </div>
                <div className={'d-flex justify-content-center ' + styles.third}>
                    <div className={'mt-3 fs-6'}>{buttonContext}</div>
                    <div className={'p-2 mt-2'}>
                        <img src={'/icon/arrow-right.svg'} alt={'right'} width={24} height={24}/>
                    </div>
                </div>
            </>
        )
    }
);

export default ExpectedTime;
