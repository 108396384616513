import { useNavigate } from 'react-router-dom';
interface Header {
  title: string,
}

const Header = ({ title }: Header) => {
  const navigate = useNavigate();
  return (
    <div className={'sticky-top'}>
      <nav>
        <div className={'w-100 text-center grid-parent'}>
          <div className={'grid-item d-flex justify-content-center'}>
            <div onClick={() => navigate('/')}>
              <img src={'/icon/chevron-left.svg'} alt={'chevron-left'} />
            </div>
          </div>
          <div className={'grid-item title'}>
            {title && <span className={'m-0 fw-semi'}>{title}</span>}
          </div>
          <div className={'grid-item d-flex justify-content-center'}>
            <img src={'/icon/help.svg'} alt={'help'} />
          </div>
        </div>
      </nav>
    </div>
  )
};

export default Header;
