import {Modal} from "react-bootstrap";
import styles from '../styles.module.scss';
import {observer} from "mobx-react-lite";
import Options from "@src/views/game/components/Step/components/Stepper";
import Image from 'react-bootstrap/Image'
import {get} from "lodash";
import {useStore} from "@src/stores";
import find from "lodash/find";
import {i18n} from "@lingui/core";

const MessageResponsePopup = observer(({stepContent, stepController}: any) => {
    const {userStore} = useStore();
    const {user} = userStore;
    const userName = user.name || i18n._('Player');

    // const { image } = stepContent
    const imageUrl =
        find(stepContent, 'image.data.attributes.url')?
        get(stepContent, 'image.data.attributes.url', ''):
            get(stepContent, 'image.url', '');
    console.log(imageUrl);
    return (
        <div className={`${styles.stepChatOptions} chat`} style={{
            // maxHeight:'100vh',
            maxWidth: '100vw',
            display: 'flex',
            alignContent: 'flex-start',
            justifyContent: 'center',
            flexWrap: 'nowrap',
            overflow: 'hidden',
        }}>
            {imageUrl && <Image key={'response-image'}
                // className={`${styles.chatCharacter}`}
                                style={{
                                    height: '100vh',
                                    position: 'relative',
                                    opacity: 0.3,
                                }}
                                src={imageUrl}
            />}
            <Modal show={true} className={'game-popup'} backdropClassName={'chat-modal-backdrop'}>
                <Modal.Body className={styles['popup-body']}>
                    {imageUrl && <div className={'text-start mb-3'}>
                        <span className={styles['chat-name']}>{userName}</span>
                        <hr className={'mt-1'}/>
                    </div>}
                    <div className={'d-block'}>
                        <Options stepController={stepController}/>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
})

export default MessageResponsePopup;
