import { Modal } from "react-bootstrap";
import styles from '../styles.module.scss';
import React, { useState } from "react";
import { updateStepper } from '@src/services/games';
import { useParams } from "react-router";
import { Button } from "@src/components/Buttons/Button";
import { Trans } from '@lingui/macro';
import Loader from "@src/components/Loader";
import { Button as BootStrapButton } from "react-bootstrap";

const ProgressBar = ({ questions, index }: any) => {
  return (
    questions.map((_: null, i: number) =>
      <div key={'bar-' + i} className={index >= i ? styles.activeDot : styles.inactiveDot}>
        <span>{i + 1}</span>
      </div>
    )
  )
}


const DisplayOptions = ({ options, setShowAnswer }: any) => {
  const [clickedIndex, setClickedIndex] = useState(65535);
  const [touched, setTouched] = useState(false);
  const checkAns = (correct: boolean, index: number) => {
    setShowAnswer(correct)
    setClickedIndex(index)
    setTouched(true)
  }
  const Options = React.Children.toArray(options.map((item: any, i: any) =>
    <div key={i} className={'d-flex justify-content-center'}
      onClick={() => {
        if(!item.correct){
          if (navigator.vibrate) {
              navigator.vibrate(200);
          }
        }
        checkAns(item.correct, i)
      }}>
      <Button color={'primary'} theme={'light'}
        className={i == clickedIndex ? styles.clicked : styles.answer}
        text={item.ans} />
      <div>
        {i == clickedIndex &&
          <img
            className={`position-absolute ${styles.checkxCircle}`}
            style={{ left: '280px' }}
            width={20}
            src={`/icon/${item.correct ? 'check-circle.svg' : 'x-circle.svg'}`} />
        }
      </div>
    </div>))
  return (
    <>
      {Options}
      {touched && <span className={styles.error}><Trans>Incorrect. Try again!</Trans></span>}
      {/* <div className={styles.hintsBox}>
                <div className={styles.hints} onClick={() => console.log('clicled')}>
                    <img src={'/icon/question-circle.svg'} alt={'questions'} className={'my-2'}/> <Trans>Hint</Trans>
                </div>
            </div> */}
    </>
  )
};
const DisplayAnswer = ({ answer, next }: any) => {
  return (
    <>
      <span className={'d-block text-align-center w-75 mx-auto opacity-50 my-5'}>
        <img
          className={'d-block mx-auto'}
          width={30}
          src={`/icon/check-circle.svg`} />
        <span>{answer}</span>
      </span>
      {/* {/* <div className={styles.hintsBox}> */}
      <div className={'d-flex justify-content-center'}>
        <Button color={'primary'} theme={'light'} onClick={() => next()}
          text={<Trans>Proceed</Trans>} />
      </div>
    </>
  )
};
const QuestionsPopup = ({ stepContent, stepController }: any) => {
  const { questions, title } = stepContent
  console.log(questions)
    console.log('stepController',stepController[0])

  const [showAnswer, setShowAnswer] = useState(false);
  const [loading, setLoading] = useState(false);
  const [index, setIndex] = useState(0);
  const { id: groupId } = useParams()
  const [showModal, setShowModal] = useState(true)
  const next = async () => {
    if (index + 1 === questions.length) {
      //stepController
      setLoading(true)
      await updateStepper(groupId as string, stepController[0].id)
      setLoading(false)
    } else {
      setIndex(index + 1)
      setShowAnswer(false)
    }
  }

  return (
    <>
      <Modal show={true} className={`question-popup ${showModal?'':'collapse'}`} size={'lg'} backdropClassName={'chat-modal-backdrop'} onHide={() => { setShowModal(false) }}>
        <Modal.Body className={showModal?styles['popup-body']:styles['popup-body-collapse']}>
          <BootStrapButton type='button' style={{
            background: '#fff',
            border: 'none',
            position: 'absolute',
            top: '0px',
            right: '28px',
            padding: 0,
            margin: 0,
            width: '28px'
          }} onClick={() => { setShowModal(!showModal) }}>
            {!showModal && <img className={'p-2'} src={'/icon/caret-up.svg'} alt={'arrow-up'} width={38}/>}
            {showModal && <img className={'p-2'} src={'/icon/caret-down.svg'} alt={'arrow-down'} width={38}/>}
          </BootStrapButton>
          {/* <div className={styles['question-icon']}>
                    <div className={styles['question-box']}>
                        <img
                            alt={'AIXO'}
                            className={'my-2'}
                            width={80}
                            height={80}
                            src={'https://storage.googleapis.com/artifacts-cms/Ellipse_23_1_33c368c24d/Ellipse_23_1_33c368c24d.png?updated_at=2022-09-20T15:56:39.141Z'}/>
                    </div>
                </div> */}
          {showModal && <>
          <div style={{ opacity: '0.5' }} className={'mt-5 mb-3'}>
            <img src={'/icon/lock.svg'} alt={'lock'} />{title}
          </div>
          <ProgressBar questions={questions} index={index} />
          <h4 className="my-3">{questions[index].question}</h4>
          {loading && <Loader position={'right'}/>}
          {showAnswer ?
            <DisplayAnswer answer={questions[index].answer} next={next} /> :
            <DisplayOptions options={questions[index].options} setShowAnswer={setShowAnswer} />}
          </>}
        </Modal.Body>
      </Modal>
    </>
  )
}

export default QuestionsPopup;
