import { useParams } from "react-router-dom";
import { observer } from 'mobx-react-lite';
import get from 'lodash/get'
import ReactPlayer from 'react-player/vimeo'
import { useRef, useState } from "react";
import screenfull from 'screenfull'
import { updateStepper } from "@src/services/games";
import style from "./style.module.scss";

const Cutscene = observer(({ stepContent, stepController }: any) => {
  const refPlayer: any = useRef();
  const { id: groupId } = useParams()
  const [show, setShow] = useState(true)
  const [showPlayButton, setShowPlayButton] = useState(false)
  const [playing, setPlaying] = useState(false)
  const onReady = () => {
    console.log('onReady')
    setShowPlayButton(true)
  }
  const handleClickFullscreen = () => {
    // if (screenfull.isEnabled) {
      screenfull.request(refPlayer.current.wrapper)
    // }
  }
  const handleEnded = () => {
    console.log('onEnded')
    // screenfull.exit()
    updateStepper(groupId as string, stepController[0].id, { skipScene: true })
    setShow(false)
    setShowPlayButton(true)
  }
  const getTextTrack = (url: string) => {
    const urlParams = new URL(url).searchParams;
    return urlParams.get('texttrack');
  }

  return (
    <>
      {showPlayButton && <div className={style['btn-wrapper']}>
        <div className={'d-flex justify-content-center'}>
          <div className={style.circleButtons} onClick={() => { setPlaying(true) }}>
            <img src={'/icon/video.svg'} alt={'play'} />
          </div>
        </div>
      </div>}
      {show && <ReactPlayer
        config={{
          playerOptions: {
            texttrack: getTextTrack(get(stepContent, 'videoUrl')),
          }
        }}
        ref={refPlayer}
        onReady={onReady}
        url={get(stepContent, 'videoUrl')}
        loop={false}
        controls={false}
        volume={1}
        muted={false}
        playing={playing}
        width={'100%'}
        height={'100%'}
        style={{ 
          position: 'fixed',
          top: 0, left: 0,
          background: 'white',
          opacity: playing? 1 : 0,
        }}
        playsinline={true}
        onClickPreview={() => { refPlayer.current.play(); console.log('onClick') }}
        onStart={() => {
          setShowPlayButton(false)
          // handleClickFullscreen();
          console.log('onStart')
        }}
        onEnded={handleEnded}
        onError={(error) => { handleEnded() }}
      />}
    </>
  )
})

export default Cutscene;
