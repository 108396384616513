import App from '@src/App'
import '@src/styles/_custom.scss'
import { createRoot } from 'react-dom/client'
const container = document.getElementById('root')
const root = createRoot(container!)
import * as Sentry from "@sentry/react";
Sentry.init({
  dsn: "https://08d93cd85c89417e99bad140f67de715@o54973.ingest.sentry.io/4505378101919744",
  replaysOnErrorSampleRate: 1.0,
  integrations: [new Sentry.Replay()],
  enabled: process.env.ENV !== 'localhost',
});
root.render(
  <App />
);
