import React from 'react';
import { Text } from 'slate';

export const SlateToReact = (nodes) => {
    return nodes.map(n => serializeNode(n)).join('');
};

const serializeNode = (node) => {
    if (Text.isText(node)) {
        let string = node.text;

        if (node.bold) {
            string = `<strong>${string}</strong>`;
        }

        if (node.italic) {
            string = `<em>${string}</em>`;
        }

        if (node.underline) {
            string = `<u>${string}</u>`;
        }

        if (node.strikethrough) {
            string = `<s>${string}</s>`;
        }

        if (node.spoiler) {
            string = `<s>${string}</s>`;
        }
        return string;
    }

    const children = node.children.map((n) => serializeNode(n)).join('');

    switch (node.type) {
        case 'paragraph':
            return `<p>${children}</p>`;
        case 'h1':
            return `<h1>${children}</h1>`;
        case 'h2':
            return `<h2>${children}</h2>`;
        case 'h3':
            return `<h3>${children}</h3>`;
        case 'h4':
            return `<h4>${children}</h4>`;
        case 'h5':
            return `<h5>${children}</h5>`;
        case 'h6':
            return `<h6>${children}</h6>`;
        case 'blockquote':
            return `<blockquote>${children}</blockquote>`;
        case 'link':
            return `<a href="${node.url}"${node.newTab ? ' target="_blank" rel="noopener noreferrer"' : ''}>${children}</a>`;
        case 'upload':
            return `<img src="${node.value.url}" alt="${node.value.filename || ''}" style="width: 100%"/>`;
        // case 'code':
        //     return `<pre>${children}</pre>`;
        case 'ul':
            return `<ul>${children}</ul>`;
        case 'ol':
            return `<ol>${children}</ol>`;
        case 'li':
            return `<li>${children}</li>`;
        default:
            return `<div>${children}</div>`;
    }
};

export const deserialize = (html) => {
    const parser = new DOMParser();
    const document = parser.parseFromString(html, 'text/html');
    const body = document.body;

    return deserializeElements(Array.from(body.childNodes));
};

export const deserializeElements = (elements) => {
    return elements.map((element) => deserializeElement(element));
};

const deserializeElement = (element) => {
    if (element.nodeType === Node.TEXT_NODE) {
        return {
            text: element.textContent,
        };
    }

    const children = deserializeElements(Array.from(element.childNodes));

    switch (element.nodeName) {
        case 'P':
            return { type: 'paragraph', children };
        case 'PRE':
            return { type: 'code', children };
        case 'STRONG':
            return { text: children[0].text, bold: true };
        default:
            return { text: element.textContent };
    }
};

