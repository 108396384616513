import Layout from "@src/components/Layout/Layout";
import styles from "./styles.module.scss";
import {Container} from "react-bootstrap";
import {useRequest} from "ahooks";
import {getPlayerInfo} from "@src/services/users";
import React, {useState, useEffect} from "react";
import InfoPopup from "@src/views/collections/InfoPopup";
import Loader from '@src/components/Loader';


const CollectionsIndex = () => {
    const {data: info, loading} = useRequest(() => getPlayerInfo())
    const [isOpen, setIsOpen] = useState(false);
    const [data, setData] = useState({});
    if (loading) return null;
    const collection = info.groupsData[0].game;
    console.log(collection);
    return (
        !info?
        <Loader/>:
        <Layout title={'ARtifact 研究'}>
            {/*<Tabs*/}
            {/*    defaultActiveKey="圖鑑"*/}
            {/*    className={styles.tab}*/}
            {/*>*/}
            {/*    <Tab eventKey="圖鑑" title="圖鑑">*/}
            {/*        /!*<Sonnet />*!/*/}
            {/*    </Tab>*/}
            {/*    <Tab eventKey="地圖" title="地圖">*/}
            {/*        /!*<Sonnet />*!/*/}
            {/*    </Tab>*/}
            {/*    <Tab eventKey="年史" title="年史">*/}
            {/*        /!*<Sonnet />*!/*/}
            {/*    </Tab>*/}
            {/*</Tabs>*/}
            <Container style={{marginBottom: '100px'}} className={'d-block text-center'}>
                <img
                    src={'https://storage.googleapis.com/artifacts-cms/2_1_3b0460e6ef/2_1_3b0460e6ef.png?updated_at=2022-09-20T11:13:59.338Z'}/>
                <h1 className={styles.title}>魂</h1>
                {/*<img*/}
                {/*    className={styles.number}*/}
                {/*    src={'https://storage.googleapis.com/artifacts-cms/14_266fe58cb2/14_266fe58cb2.png?updated_at=2022-09-28T10:36:20.950Z'}/>*/}
                <div className={'row'}>
                    <div className={styles.description}> 有故事的靈魂，能透過緣之鏡與TA互動</div>
                    {/*</div>*/}
                </div>
                <div className={'row'}>
                    {
                        collection['step-artifact-character']?.length > 0 ?
                            collection['step-artifact-character'].map((el: any) => {
                                const avatarData = el?.avatar.data.attributes
                                return (
                                    <div key={avatarData.caption} className={'col-6 my-2'} onClick={()=> {
                                        setData({...el,type:'魂'})
                                        setIsOpen(true);
                                    }}>
                                        <img src={avatarData.url} alt={avatarData.alternativeText} height={120}/>
                                        <div>{avatarData.name}</div>
                                        <div><img style={{width: '16px'}} src={'/icon/map-pin.svg'} alt={'map'}/>三保松原
                                        </div>
                                    </div>
                                )
                            }) :
                            <div className={'d-flex justify-content-center'}>
                                <div className={styles.empty}>暫時空空如也，可以在任務中找尋回憶！</div>
                            </div>
                    }
                    {/*{*/}
                    {/*    collection['step-artifact-character']?.length !== 0 &&*/}
                    {/*    (collection['step-artifact-character']?.length < 3) &&*/}
                    {/*    [...Array(3 - collection['step-artifact-item']?.length)].map((a, i) =>*/}
                    {/*        <div className={styles.ele} key={'empty-item-' + i}>*/}
                    {/*            <img src={'/empty/empty-character.png'} alt={'empty-item'}/>*/}
                    {/*        </div>*/}
                    {/*    )*/}
                    {/*}*/}
                </div>
                <hr style={{color: 'rgba(0, 0, 0, 0.2)'}}/>
                <img
                    src={'https://storage.googleapis.com/artifacts-cms/2_1_3b0460e6ef/2_1_3b0460e6ef.png?updated_at=2022-09-20T11:13:59.338Z'}/>
                <h1 className={styles.title}>物</h1>
                {/*<img*/}
                {/*    className={styles.number}*/}
                {/*    src={'https://storage.googleapis.com/artifacts-cms/14_266fe58cb2/14_266fe58cb2.png?updated_at=2022-09-28T10:36:20.950Z'}/>*/}
                <div className={'row'}>
                    <div className={styles.description}>緣之物 - 有紀念意義的物件</div>
                </div>
                <div className={collection['step-artifact-item']?.length === 0 ? 'row' :styles['row-wrapper']}>
                    {
                        collection['step-artifact-item']?.length > 0 ?
                            collection['step-artifact-item'].map((el: any) => {
                                const avatarData = el?.avatar.data.attributes
                                return (
                                    <div key={avatarData.caption} className={styles.ele} onClick={()=> {
                                        setData({...el,type:'物'})
                                        setIsOpen(true);
                                    }}>
                                        <img src={avatarData.url} alt={avatarData.alternativeText}/>
                                        <div>{avatarData.name}</div>
                                    </div>
                                )
                            }) :
                            <div className={'d-flex justify-content-center'}>
                                <div className={styles.empty}>暫時空空如也，可以在任務中找尋回憶！</div>
                            </div>
                    }
                    {
                        collection['step-artifact-item']?.length !== 0 &&
                        (collection['step-artifact-item']?.length < 3) &&
                        [...Array(3 - collection['step-artifact-item']?.length)].map((a, i) =>
                            <div className={styles.ele} key={'empty-item-' + i}>
                                <img src={'/empty/empty-item.png'} alt={'empty-item'}/>
                            </div>
                        )
                    }
                </div>
                <hr style={{color: 'rgba(0, 0, 0, 0.2)'}}/>
                <img
                    src={'https://storage.googleapis.com/artifacts-cms/2_1_3b0460e6ef/2_1_3b0460e6ef.png?updated_at=2022-09-20T11:13:59.338Z'}/>
                <h1 className={styles.title}>憶</h1>
                {/*<img*/}
                {/*    className={styles.number}*/}
                {/*    src={'https://storage.googleapis.com/artifacts-cms/14_266fe58cb2/14_266fe58cb2.png?updated_at=2022-09-28T10:36:20.950Z'}/>*/}
                <div className={'row'}>
                    <div className={styles.description}>時空記憶碎片 - 銘記重要時刻</div>
                </div>
                <div className={collection['step-artifact-memory']?.length === 0 ? 'row' :styles['row-wrapper']}>
                    {
                        collection['step-artifact-memory']?.length > 0 ?
                            collection['step-artifact-memory'].map((el: any) => {
                                const avatarData = el?.avatar.data.attributes
                                return (
                                    <div key={avatarData.caption} className={styles.ele} onClick={()=> {
                                        setData({...el,type:'憶'})
                                        setIsOpen(true);
                                    }}>
                                        <img src={avatarData.url} alt={avatarData.alternativeText}/>
                                        <div>{avatarData.name}</div>
                                        <div><img style={{width: '16px'}} src={'/icon/map-pin.svg'} alt={'map'}/>三保松原
                                        </div>
                                    </div>
                                )
                            }) :
                            <div className={'d-flex justify-content-center'}>
                                <div className={styles.empty}>暫時空空如也，可以在任務中找尋回憶！</div>
                            </div>
                    }
                    {
                        (collection['step-artifact-memory']?.length < 3 && collection['step-artifact-memory']?.length !== 0) &&
                        [...Array(3 - collection['step-artifact-memory']?.length)].map((a, i) =>
                            <div className={styles.ele} key={'empty-memory-' + i}>
                                <img src={'/empty/empty-memory.png'} alt={'empty-memory'}/>
                            </div>
                        )
                    }
                </div>
                <hr style={{color: 'rgba(0, 0, 0, 0.2)'}}/>
                <img
                    src={'https://storage.googleapis.com/artifacts-cms/2_1_3b0460e6ef/2_1_3b0460e6ef.png?updated_at=2022-09-20T11:13:59.338Z'}/>
                <h1 className={styles.title}>識</h1>
                {/*<img*/}
                {/*    className={styles.number}*/}
                {/*    src={'https://storage.googleapis.com/artifacts-cms/14_266fe58cb2/14_266fe58cb2.png?updated_at=2022-09-28T10:36:20.950Z'}/>*/}
                <div className={'row'}>
                    <div className={styles.description}>知識書籤 - 隨地掉落的知識點</div>
                </div>
                <div className={'row ' + styles.knowledgeOuter}>
                    {
                        collection['step-artifact-knowledge']?.length > 0 ?
                            collection['step-artifact-knowledge'].slice(0,2).map((el: any) => {
                                const avatarData = el?.avatar.data.attributes
                                return (
                                    <div key={avatarData.caption} className={'col-6 ' + styles.knowledge} onClick={()=> {
                                        setData({...el,type:'識'})
                                        setIsOpen(true);
                                    }}>
                                        <span>
                                            <div className={'mb-2'}>
                                                <img style={{width: '16px'}} src={'/icon/map-pin.svg'} alt={'map'}/>
                                                {el.name}
                                            </div>
                                            {el.description}</span>
                                        <img src={'/empty/empty-knowledge.png'} alt={'empty-knowledge'}
                                             className={styles['empty-knowledge']}/>

                                    </div>
                                )
                            }) :
                            <div className={'d-flex justify-content-center'}>
                                <div className={styles.empty}>暫時空空如也，可以在任務中找尋回憶！</div>
                            </div>
                    }
                </div>
                <hr style={{color: 'rgba(0, 0, 0, 0.2)'}}/>
            </Container>
            <InfoPopup show={isOpen} setShow={setIsOpen} data={data}/>
        </Layout>
    )

}

export default CollectionsIndex;
