import styles from "@src/views/game/components/Step/styles.module.scss";
import { observer } from "mobx-react-lite";
import { Trans } from "@lingui/macro";

const Congratulations = ({ stepContent }: any) => {
  return (
    <>
      <div className={styles.ScrollSection}>
        <div className={'d-block'}>
          <div className={styles.center}>
            <div className={styles['yuenTitle']}>{'緣滿'}</div>
          </div>
          <div className={'w-75 ms-auto me-auto'}>
            <Trans>Here&apos;s your mission report:</Trans>
          </div>
        </div>
      </div>
    </>
  )
}

export default observer(Congratulations);
