export const getPlayerInfo  = () => {
  return fetch(`/api/v0/artifacts/users/me`, {
    method: 'GET',
    headers: {
      authorization: sessionStorage.getItem('firebaseToken'),
      'Content-Type': 'application/json',
    }
  } as any).then((res) => res.json())
}

export const checkEmailExist  = (email: string) => {
  return fetch(`/api/v0/artifacts/users/email`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      email
    })
  } as any).then((res) => res.status)
}
export const updateProfile  = (data: any) => {
  return fetch(`/api/v0/artifacts/users/profile`, {
    method: 'POST',
    headers: {
      authorization: sessionStorage.getItem('firebaseToken'),
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data)
  } as any).then((res) => res.status)
}