'use strict';
import 'material-symbols';
import { observer } from "mobx-react-lite";
import { useStore } from '@src/stores';

export default observer(({item} : any) => {
  const { userStore } = useStore();
    return (
      <header className={'sticky-top'}>
          <nav>
              <div className={'w-100 text-center grid-parent'}>
                  <div className={'bordered-grid-item title-home'}>
                      <div className={'row'}>
                          <div className={'col'}>
                              <span className={'ps-2 mt-1'}>{item.title}</span>
                          </div>
                      </div>
                  </div>
                  <div className={'bordered-grid-item d-flex justify-content-center title-home-exp'}>
                      <span className={'mt-2'}>{userStore.user.point} En</span>
                  </div>
                  <div className={'bordered-grid-item d-flex justify-content-center'}>
                      <div className={'mt-2'}>
                          <img src={'/icon/more-vertical.svg'} alt={'more'}/>
                      </div>
                  </div>
              </div>
          </nav>
      </header>
  )
})
