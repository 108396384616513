import { Navigate } from 'react-router-dom';
// import { lazy } from "react";

// const Home = lazy(() => import('@src/views/home'));
// const Game = lazy(() => import('@src/views/game'));
// const Mission = lazy(() => import('@src/views/mission'));
// const CollectionsIndex = lazy(() => import('@src/views/collections'));
// const CollectionsMission = lazy(() => import('@src/views/collections/[id]'));

import Home from '@src/views/home';
import Game from '@src/views/game';
import Mission from '@src/views/mission';
import CollectionsIndex from '@src/views/collections';
import CollectionsMission from '@src/views/collections/[id]';

// import Home from '@src/views/home';
// import Game from '@src/views/game';
// import Mission from '@src/views/mission';
// import CollectionsIndex from '@src/views/collections';
// import CollectionsMission from '@src/views/collections/[id]';

export const protectedRoutes = [
  {
    path: '/home',
    element: <Home />,
  },
  {
    path: '/game/:id',
    element: <Game />,
  },
  {
    path: '/mission/:id',
    element: <Mission />,
  },
  {
    path: '/collections/:id',
    element: <CollectionsMission />,
  },
  {
    path: '/collections',
    element: <CollectionsIndex />
  },
  {
    path: '/*',
    element: <Navigate to="/home" replace />,
  },
];