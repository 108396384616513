import { observer } from "mobx-react-lite";
import styles from "@src/views/mission/style/mission.module.scss";

const MissionDetails = observer(({ children, xpReward }: any) => {
  return (
    <>
      <div className={styles.wrapper}>
        {children}
      </div>
      <div className={'row justify-content-center ' + styles.xp}>
        <div className="col-6">
          {xpReward}
        </div>
        <div className="col-2">
          <img src={'/icon/help-circle.svg'} alt={'help-circle'} />
        </div>
      </div>
    </>
  )
}
);

export default MissionDetails;