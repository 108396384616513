import styles from "@src/views/game/components/Step/Summary/styles.module.scss";
import { Button, Col, Row } from "react-bootstrap";
// import Slider from "react-slick";
// import SharePopup from "@src/views/game/components/Step/Summary/components/SharePopup";
// import NewUserPopup from "@src/views/game/components/Step/Summary/components/NewUser";
import { Trans } from '@lingui/macro';
// import Loader from "@src/components/Loader";
// import { useRequest } from "ahooks";
// import { useParams } from "react-router";
// import { getFinale } from "@src/services/games";
import { observer } from "mobx-react-lite";
// import { useStore } from "@src/stores";
import get from "lodash/get";
import Options from "@src/views/game/components/Step/components/Stepper";
// import { toJS } from "mobx";
import { Image } from 'react-bootstrap'

const Summary = ({ stepContent, stepController }: any) => {
  // const { id: groupId } = useParams()
  // console.log(toJS(stepContent))
  // const [isSharePopup, toggleSharePopup] = useState(false);
  // const { userStore } = useStore();
  const background = get(stepContent, 'background.data.attributes.url', '/finale_places.png')
  const reviewUrl = get(stepContent, 'reviewUrl', '')
  // const { data, loading } = useRequest(() => getFinale(groupId as string))
  // if (loading) return <Loader />

  // const First =
  //   <div className={styles.ScrollSection}>
  //     <div className={'d-block'}>
  //       <div className={styles.center}>
  //         <div className={styles['yuenTitle']}>{'緣滿'}</div>
  //       </div>
  //       <div className={'w-75 ms-auto me-auto'}>
  //         <Trans>Here&apos;s your mission report:</Trans>
  //       </div>
  //     </div>
  //   </div>
  // const Map =
  //   <div className={styles.ScrollSection}>
  //     <div className={'mt-4 mb-5'}>
  //       <Trans>Within</Trans>
  //     </div>
  //     <h4 className={'d-block'}>
  //       <Trans>[Game play duration].</Trans>
  //     </h4>
  //     <div className={'d-block'}>
  //       <Trans>You have...</Trans>
  //     </div>
  //   </div>

  // const Artifacts =
  //   <div className={styles.ScrollSection}>
  //     <div className={styles.center}>
  //       <div className={'mt-1 mb-3'}><Trans>Collected</Trans></div>
  //     </div>
  //     <div className={styles.background}>
  //       <img src={background} alt={'finale_places'} />
  //     </div>
  //     <Row>
  //       <Col xs={4} className={'d-block text-center'}>
  //         <div
  //           className={styles.number}>{get(data,'artifacts.step-artifact-item', []).length}</div>
  //         <h4><Trans>Item</Trans>s</h4>
  //       </Col>
  //       <Col xs={4} className={'d-block text-center'}>
  //         <div
  //           className={styles.number}>{get(data,'artifacts.step-artifact-memory', []).length}</div>
  //         <h4><Trans>Memor(ies)</Trans></h4>
  //       </Col>
  //       <Col xs={4} className={'d-block text-center'}>
  //         <div
  //           className={styles.number}>{get(data,'artifacts.step-artifact-knowledge', []).length}</div>
  //         <h4><Trans>Pieces of Knowledge</Trans></h4>
  //       </Col>
  //     </Row>
  //     <div className={styles.center}><Trans>all the while helping to protect the ecosystem.</Trans></div>
  //   </div>
  // const Characters =
  //   <div className={styles.ScrollSection}>
  //     <div className={styles.center}>
  //       <div className={'mt-4 mb-5'}><Trans>You have also built relationship(s) with </Trans></div>
  //     </div>
  //     <div className={styles.characterRow}>
  //       <Row>
  //         {
  //           get(data,'artifacts.step-artifact-character', []).map((character: any) => {
  //             return (
  //               <>
  //                 <Col xs={4}>
  //                   <img
  //                     src={character.avatar.data.attributes.url} />
  //                 </Col>
  //                 <Col xs={8}>
  //                   <div className={styles.caption}>
  //                     {character.description}
  //                   </div>
  //                 </Col>
  //                 <Col xs={4}>
  //                   <img
  //                     src={character.avatar.data.attributes.url} />
  //                 </Col>
  //                 <Col xs={8}>
  //                   <div className={styles.caption}>
  //                     {character.description}
  //                   </div>
  //                 </Col>
  //               </>
  //             )
  //           })
  //         }
  //       </Row>
  //     </div>
  //     <div className={styles.center}>
  //       <div className={'mt-4 mb-5'}><Trans>...</Trans></div>
  //     </div>
  //   </div>


  // const Ending =
  //   <>
  //     <div className={styles.ScrollSection}>
  //       {/*<div className={styles.center}>
  //         <div className={'mt-4 mb-5'}><Trans>Last but not lesat, you have built</Trans></div>
  //       </div>
  //       <div className={'text-center'} id={'xp'}>
  //         <span className={styles.subtitle}>{get(data, 'en')}</span>
  //         <span className={styles.verticalWriting}>En</span>
  //       </div>
  //       <div className={'text-center'} id={'rank'}>
  //         <div className={'mt-4 mb-5'}><Trans>putting you on</Trans></div>
  //         <span className={styles.subtitle}>[rank]</span>
  //         <div className={'mt-4 mb-5'}><Trans>Rank</Trans></div>
  //       </div>*/}
  //       {/*<div className={'text-center'}>
  //         <div className={'mt-4 mb-5'}>Earning</div>
  //         <span className={styles.subtitle}>{get(data, 'xp')}</span>
  //         <span className={styles.verticalWriting}>XP</span>
  //       </div>
  //       <div className={styles.center}>
  //         <h5 className={'mt-5 fw-bold'}><Trans>To collect your XP</Trans></h5>
  //       </div>*/}
  //       {/* {userStore.isAnonymous && <div className={'text-center'}>
  //         <div>Do you want to save you process?</div>
  //         <Button className={styles.finaleButton} onClick={() => userStore.linknToProvider('google')}>
  //           <Trans>Link To Google</Trans>
  //         </Button>
  //       </div>} */}
  //       <div><Trans>Improve this experience:</Trans></div>
  //       <Button className={styles.reviewButton} onClick={() => window.open(`${reviewUrl}`)}>
  //         <h4><Trans>Review this Mission</Trans></h4>
  //       </Button>
  //       <div className="mt-4"><Trans>Scroll right for a surprise!!</Trans></div>
  //     </div>
  //     {/* <NewUserPopup setShow={toggleNewUserPopup} show={isNewUserPopup} /> */}
  //   </>
  return (
    <>
      <div className={styles.ScrollSection}>
        <Image className={styles.background} src={background} fluid />
        {stepContent.title && <h4>{stepContent.title}</h4>}
        {stepContent.description && <h5>{stepContent.description}</h5>}
        <Options stepController={stepController} />
      </div>
    </>
  )
}

export default observer(Summary);
