import style from "./style.module.scss";
// import { useNavigate } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { useStore } from "@src/stores";
import { createTimeModel, useTimeModel } from "react-compound-timer";
import { TimeModelValueView } from "./components/TimeModelValueView";
import { useCallback, useEffect, useReducer, useState } from "react";
import { updateStepper } from "@src/services/games";
import { useParams } from "react-router";

const content = [
  'OK. Fine.',
  "NO!",
  'NO...',
  "NO",
  'Please enjoy this time.',
  'I am not ready yet.',
  'Patience is not simply the ability to wait - it\'s how we behave while we are waiting',
  'To lose patience is to lose the battle.',
  'Patience is a virtue',
  'Nice to meet you.',
]
let pressedCount = 0;
const formatDoubleDigi = (value: number) => {
  return value < 10 ? `0${value}` : value;
}
const Meditation = ({ stepContent, stepController }: any) => {
  const { fireStore } = useStore();
  const { id: groupId } = useParams()
  const [timeModal, setTimeModal] = useState(createTimeModel({
    initialTime: stepContent.end - Date.now(),
    direction: "backward",
  }))
  const { value } = useTimeModel(timeModal);
  const checkTimeup = () => {
    if (value.state == 'STOPPED' || pressedCount > 9) {
      updateStepper(groupId as string, stepController[0].id, { skipScene: true })
    } else {
      pressedCount++;
      console.log(pressedCount);
      alert(content.pop() || 'No')
    }
  }
  const formatedTimeValue = useCallback((value: any) => {
    if(value.state == 'STOPPED') return 'Ready!'
    return `${formatDoubleDigi(value.h)}:${formatDoubleDigi(value.m)}:${formatDoubleDigi(value.s)}`
  }, [])
  return (
    <>
      <div className={style['btn-wrapper']}>
        <div className={'d-flex justify-content-center'}>
          <div className={style.circleButtons} onClick={() => { checkTimeup() }}>
            <TimeModelValueView title="Timer" description="" value={formatedTimeValue(value)} />
          </div>
        </div>
      </div>
    </>
  )
}
export default observer(Meditation);
